const countryList = {
    countries: [
        {
            code: "DE",
            nameTr: "Almanya",
            nameEn: "Germany",
            nameDe: "Deutschland",
            phoneCode: "+49"
        },
        {
            code: "TR",
            nameTr: "Türkiye",
            nameEn: "Türkiye",
            nameDe: "Türkiye",
            phoneCode: "+90"
        },
        {
            code: "FR",
            nameTr: "Fransa",
            nameEn: "France",
            nameDe: "Frankreich",
            phoneCode: "+33"
        },
        {
            code: "NL",
            nameTr: "Hollanda",
            nameEn: "Netherlands",
            nameDe: "Niederlande",
            phoneCode: "+31"
        },
        {
            code: "BE",
            nameTr: "Belçika",
            nameEn: "Belgium",
            nameDe: "Belgien",
            phoneCode: "+32"
        },
        {
            code: "AF",
            nameTr: "Afganistan",
            nameEn: "Afghanistan",
            nameDe: "Afghanistan",
            phoneCode: "+93"
        },
        {
            code: "AL",
            nameTr: "Arnavutluk",
            nameEn: "Albania",
            nameDe: "Albanien",
            phoneCode: "+355"
        },
        {
            code: "DZ",
            nameTr: "Cezayir",
            nameEn: "Algeria",
            nameDe: "Algerien",
            phoneCode: "+213"
        },
        {
            code: "AO",
            nameTr: "Angola",
            nameEn: "Angola",
            nameDe: "Angola",
            phoneCode: "+244"
        },
        {
            code: "AG",
            nameTr: "Antigua ve Barbuda",
            nameEn: "Antigua and Barbuda",
            nameDe: "Antigua und Barbuda",
            phoneCode: "+1268"
        },
        {
            code: "AR",
            nameTr: "Arjantin",
            nameEn: "Argentina",
            nameDe: "Argentinien",
            phoneCode: "+54"
        },
        {
            code: "AM",
            nameTr: "Ermenistan",
            nameEn: "Armenia",
            nameDe: "Armenien",
            phoneCode: "+374"
        },
        {
            code: "AU",
            nameTr: "Avustralya",
            nameEn: "Australia",
            nameDe: "Australien",
            phoneCode: "+61"
        },
        {
            code: "AT",
            nameTr: "Avusturya",
            nameEn: "Austria",
            nameDe: "Österreich",
            phoneCode: "+43"
        },
        {
            code: "AZ",
            nameTr: "Azerbaycan",
            nameEn: "Azerbaijan",
            nameDe: "Aserbaidschan",
            phoneCode: "+994"
        },
        {
            code: "BH",
            nameTr: "Bahreyn",
            nameEn: "Bahrain",
            nameDe: "Bahrain",
            phoneCode: "+973"
        },
        {
            code: "BD",
            nameTr: "Bangladeş",
            nameEn: "Bangladesh",
            nameDe: "Bangladesh",
            phoneCode: "+880"
        },
        {
            code: "BY",
            nameTr: "Belarus",
            nameEn: "Belarus",
            nameDe: "Belarus",
            phoneCode: "+375"
        },
        {
            code: "BZ",
            nameTr: "Belize",
            nameEn: "Belize",
            nameDe: "Belize",
            phoneCode: "+501"
        },
        {
            code: "BJ",
            nameTr: "Benin",
            nameEn: "Benin",
            nameDe: "Benin",
            phoneCode: "+229"
        },
        {
            code: "BT",
            nameTr: "Butan",
            nameEn: "Bhutan",
            nameDe: "Bhutan",
            phoneCode: "+975"
        },
        {
            code: "BO",
            nameTr: "Bolivya",
            nameEn: "Bolivia",
            nameDe: "Bolivien",
            phoneCode: "+591"
        },
        {
            code: "BA",
            nameTr: "Bosna-Hersek",
            nameEn: "Bosnia and Herzegovina",
            nameDe: "Bosnien und Herzegowina",
            phoneCode: "+387"
        },
        {
            code: "BW",
            nameTr: "Botsvana",
            nameEn: "Botswana",
            nameDe: "Botsuana",
            phoneCode: "+267"
        },
        {
            code: "BR",
            nameTr: "Brezilya",
            nameEn: "Brazil",
            nameDe: "Brasilien",
            phoneCode: "+55"
        },
        {
            code: "BN",
            nameTr: "Brunei",
            nameEn: "Brunei",
            nameDe: "Brunei",
            phoneCode: "+673"
        },
        {
            code: "BG",
            nameTr: "Bulgaristan",
            nameEn: "Bulgaria",
            nameDe: "Bulgarien",
            phoneCode: "+359"
        },
        {
            code: "BF",
            nameTr: "Burkina Faso",
            nameEn: "Burkina Faso",
            nameDe: "Burkina Faso",
            phoneCode: "+226"
        },
        {
            code: "BI",
            nameTr: "Burundi",
            nameEn: "Burundi",
            nameDe: "Burundi",
            phoneCode: "+257"
        },
        {
            code: "KH",
            nameTr: "Kamboçya",
            nameEn: "Cambodia",
            nameDe: "Kambodscha",
            phoneCode: "+855"
        },
        {
            code: "CM",
            nameTr: "Kamerun",
            nameEn: "Cameroon",
            nameDe: "Kamerun",
            phoneCode: "+237"
        },
        {
            code: "CA",
            nameTr: "Kanada",
            nameEn: "Canada",
            nameDe: "Kanada",
            phoneCode: "+1"
        },
        {
            code: "CV",
            nameTr: "Yeşil Burun Adaları",
            nameEn: "Cape Verde",
            nameDe: "Kap Verde",
            phoneCode: "+238"
        },
        {
            code: "KY",
            nameTr: "Cayman Adaları",
            nameEn: "Cayman Islands",
            nameDe: "Kaimaninseln",
            phoneCode: "+1345"
        },
        {
            code: "CF",
            nameTr: "Orta Afrika Cumhuriyeti",
            nameEn: "Central African Republic",
            nameDe: "Zentralafrikanische Republik",
            phoneCode: "+236"
        },
        {
            code: "TD",
            nameTr: "Çad",
            nameEn: "Chad",
            nameDe: "Tschad",
            phoneCode: "+235"
        },
        {
            code: "CL",
            nameTr: "Şili",
            nameEn: "Chile",
            nameDe: "Chile",
            phoneCode: "+56"
        },
        {
            code: "CN",
            nameTr: "Çin",
            nameEn: "China",
            nameDe: "China",
            phoneCode: "+86"
        },
        {
            code: "CO",
            nameTr: "Kolombiya",
            nameEn: "Colombia",
            nameDe: "Kolumbien",
            phoneCode: "+57"
        },
        {
            code: "KM",
            nameTr: "Komorlar",
            nameEn: "Comoros",
            nameDe: "Komoren",
            phoneCode: "+269"
        },
        {
            code: "CK",
            nameTr: "Cook Adaları",
            nameEn: "Cook Islands",
            nameDe: "Cookinseln",
            phoneCode: "+682"
        },
        {
            code: "CR",
            nameTr: "Kosta Rika",
            nameEn: "Costa Rica",
            nameDe: "Costa Rica",
            phoneCode: "+506"
        },
        {
            code: "HR",
            nameTr: "Hırvatistan",
            nameEn: "Croatia",
            nameDe: "Kroatien",
            phoneCode: "+385"
        },
        {
            code: "CU",
            nameTr: "Küba",
            nameEn: "Cuba",
            nameDe: "Kuba",
            phoneCode: "+53"
        },
        {
            code: "CY",
            nameTr: "Kıbrıs",
            nameEn: "Cyprus",
            nameDe: "Cyprus",
            phoneCode: "+357"
        },
        {
            code: "CZ",
            nameTr: "Çek Cumhuriyeti",
            nameEn: "Czechia",
            nameDe: "Tschechien",
            phoneCode: "+420"
        },
        {
            code: "CD",
            nameTr: "Demokratik Kongo Cumhuriyeti",
            nameEn: "Democratic Republic of the Congo",
            nameDe: "Demokratische Republik Kongo",
            phoneCode: "+243"
        },
        {
            code: "DK",
            nameTr: "Danimarka",
            nameEn: "Denmark",
            nameDe: "Dänemark",
            phoneCode: "+45"
        },
        {
            code: "DJ",
            nameTr: "Cibuti",
            nameEn: "Djibouti",
            nameDe: "Dschibuti",
            phoneCode: "+253"
        },
        {
            code: "DM",
            nameTr: "Dominika",
            nameEn: "Dominica",
            nameDe: "Dominica",
            phoneCode: "+1767"
        },
        {
            code: "DO",
            nameTr: "Dominik Cumhuriyeti",
            nameEn: "Dominican Republic",
            nameDe: "Dominikanische Republik",
            phoneCode: "+1809"
        },
        {
            code: "TL",
            nameTr: "Doğu Timor",
            nameEn: "East Timor",
            nameDe: "Osttimor",
            phoneCode: "+670"
        },
        {
            code: "EC",
            nameTr: "Ekvador",
            nameEn: "Ecuador",
            nameDe: "Ecuador",
            phoneCode: "+593"
        },
        {
            code: "EG",
            nameTr: "Mısır",
            nameEn: "Egypt",
            nameDe: "Ägypten",
            phoneCode: "+20"
        },
        {
            code: "SV",
            nameTr: "El Salvador",
            nameEn: "El Salvador",
            nameDe: "El Salvador",
            phoneCode: "+503"
        },
        {
            code: "GQ",
            nameTr: "Ekvator Ginesi",
            nameEn: "Equatorial Guinea",
            nameDe: "Äquatorialguinea",
            phoneCode: "+240"
        },
        {
            code: "ER",
            nameTr: "Eritre",
            nameEn: "Eritrea",
            nameDe: "Eritrea",
            phoneCode: "+291"
        },
        {
            code: "EE",
            nameTr: "Estonya",
            nameEn: "Estonia",
            nameDe: "Estland",
            phoneCode: "+372"
        },
        {
            code: "SZ",
            nameTr: "Esvatini",
            nameEn: "Eswatini",
            nameDe: "Eswatini",
            phoneCode: "+268"
        },
        {
            code: "ET",
            nameTr: "Etiyopya",
            nameEn: "Ethiopia",
            nameDe: "Äthiopien",
            phoneCode: "+251"
        },
        {
            code: "FO",
            nameTr: "Faroe Adaları",
            nameEn: "Faroe Islands",
            nameDe: "Färöer",
            phoneCode: "+298"
        },
        {
            code: "FJ",
            nameTr: "Fiji",
            nameEn: "Fiji",
            nameDe: "Fidschi",
            phoneCode: "+679"
        },
        {
            code: "FI",
            nameTr: "Finlandiya",
            nameEn: "Finland",
            nameDe: "Finnland",
            phoneCode: "+358"
        },
        {
            code: "GF",
            nameTr: "Fransız Guyanası",
            nameEn: "French Guiana",
            nameDe: "Französisch-Guayana",
            phoneCode: "+594"
        },
        {
            code: "GA",
            nameTr: "Gabon",
            nameEn: "Gabon",
            nameDe: "Gabun",
            phoneCode: "+241"
        },
        {
            code: "GE",
            nameTr: "Gürcistan",
            nameEn: "Georgia",
            nameDe: "Georgien",
            phoneCode: "+995"
        },
        {
            code: "GH",
            nameTr: "Gana",
            nameEn: "Ghana",
            nameDe: "Ghana",
            phoneCode: "+233"
        },
        {
            code: "GI",
            nameTr: "Cebelitarık",
            nameEn: "Gibraltar",
            nameDe: "Gibraltar",
            phoneCode: "+350"
        },
        {
            code: "GR",
            nameTr: "Yunanistan",
            nameEn: "Greece",
            nameDe: "Griechenland",
            phoneCode: "+30"
        },
        {
            code: "GD",
            nameTr: "Grenada",
            nameEn: "Grenada",
            nameDe: "Grenada",
            phoneCode: "+1473"
        },
        {
            code: "GP",
            nameTr: "Guadalupe",
            nameEn: "Guadeloupe",
            nameDe: "Guadeloupe",
            phoneCode: "+590"
        },
        {
            code: "GT",
            nameTr: "Guatemala",
            nameEn: "Guatemala",
            nameDe: "Guatemala",
            phoneCode: "+502"
        },
        {
            code: "GG",
            nameTr: "Guernsey",
            nameEn: "Guernsey",
            nameDe: "Guernsey",
            phoneCode: "+44"
        },
        {
            code: "GN",
            nameTr: "Gine",
            nameEn: "Guinea",
            nameDe: "Guinea",
            phoneCode: "+224"
        },
        {
            code: "GW",
            nameTr: "Gine-Bissau",
            nameEn: "Guinea-Bissau",
            nameDe: "Guinea-Bissau",
            phoneCode: "+245"
        },
        {
            code: "HT",
            nameTr: "Haiti",
            nameEn: "Haiti",
            nameDe: "Haiti",
            phoneCode: "+509"
        },
        {
            code: "HU",
            nameTr: "Macaristan",
            nameEn: "Hungary",
            nameDe: "Ungarn",
            phoneCode: "+36"
        },
        {
            code: "IS",
            nameTr: "İzlanda",
            nameEn: "Iceland",
            nameDe: "Island",
            phoneCode: "+354"
        },
        {
            code: "IN",
            nameTr: "Hindistan",
            nameEn: "India",
            nameDe: "Indien",
            phoneCode: "+91"
        },
        {
            code: "ID",
            nameTr: "Endonezya",
            nameEn: "Indonesia",
            nameDe: "Indonesien",
            phoneCode: "+62"
        },
        {
            code: "IR",
            nameTr: "İran",
            nameEn: "Iran",
            nameDe: "Iran",
            phoneCode: "+98"
        },
        {
            code: "IQ",
            nameTr: "Irak",
            nameEn: "Iraq",
            nameDe: "Irak",
            phoneCode: "+964"
        },
        {
            code: "IE",
            nameTr: "İrlanda",
            nameEn: "Ireland",
            nameDe: "Irland",
            phoneCode: "+353"
        },
        {
            code: "IM",
            nameTr: "Man Adası",
            nameEn: "Isle of Man",
            nameDe: "Insel Man",
            phoneCode: "+44"
        },
        {
            code: "IL",
            nameTr: "İsrail",
            nameEn: "Israel",
            nameDe: "Israel",
            phoneCode: "+972"
        },
        {
            code: "IT",
            nameTr: "İtalya",
            nameEn: "Italy",
            nameDe: "Italien",
            phoneCode: "+39"
        },
        {
            code: "CI",
            nameTr: "Fildişi Sahili",
            nameEn: "Ivory Coast",
            nameDe: "Elfenbeinküste",
            phoneCode: "+225"
        },
        {
            code: "JM",
            nameTr: "Jamaika",
            nameEn: "Jamaica",
            nameDe: "Jamaika",
            phoneCode: "+1876"
        },
        {
            code: "JP",
            nameTr: "Japonya",
            nameEn: "Japan",
            nameDe: "Japan",
            phoneCode: "+81"
        },
        {
            code: "JE",
            nameTr: "Jersey",
            nameEn: "Jersey",
            nameDe: "Jersey",
            phoneCode: "+44"
        },
        {
            code: "JO",
            nameTr: "Ürdün",
            nameEn: "Jordan",
            nameDe: "Jordanien",
            phoneCode: "+962"
        },
        {
            code: "KZ",
            nameTr: "Kazakistan",
            nameEn: "Kazakhstan",
            nameDe: "Kasachstan",
            phoneCode: "+7"
        },
        {
            code: "KE",
            nameTr: "Kenya",
            nameEn: "Kenya",
            nameDe: "Kenia",
            phoneCode: "+254"
        },
        {
            code: "KI",
            nameTr: "Kiribati",
            nameEn: "Kiribati",
            nameDe: "Kiribati",
            phoneCode: "+686"
        },
        {
            code: "XK",
            nameTr: "Kosova",
            nameEn: "Kosovo",
            nameDe: "Kosovo",
            phoneCode: "+383"
        },
        {
            code: "KW",
            nameTr: "Kuveyt",
            nameEn: "Kuwait",
            nameDe: "Kuwait",
            phoneCode: "+965"
        },
        {
            code: "KG",
            nameTr: "Kırgızistan",
            nameEn: "Kyrgyzstan",
            nameDe: "Kirgisistan",
            phoneCode: "+996"
        },
        {
            code: "LV",
            nameTr: "Letonya",
            nameEn: "Latvia",
            nameDe: "Lettland",
            phoneCode: "+371"
        },
        {
            code: "LB",
            nameTr: "Lübnan",
            nameEn: "Lebanon",
            nameDe: "Libanon",
            phoneCode: "+961"
        },
        {
            code: "LR",
            nameTr: "Liberya",
            nameEn: "Liberia",
            nameDe: "Liberia",
            phoneCode: "+231"
        },
        {
            code: "LY",
            nameTr: "Libya",
            nameEn: "Libya",
            nameDe: "Libyen",
            phoneCode: "+218"
        },
        {
            code: "LT",
            nameTr: "Litvanya",
            nameEn: "Lithuania",
            nameDe: "Litauen",
            phoneCode: "+370"
        },
        {
            code: "LU",
            nameTr: "Lüksemburg",
            nameEn: "Luxembourg",
            nameDe: "Luxemburg",
            phoneCode: "+352"
        },
        {
            code: "MG",
            nameTr: "Madagaskar",
            nameEn: "Madagascar",
            nameDe: "Madagaskar",
            phoneCode: "+261"
        },
        {
            code: "MW",
            nameTr: "Malavi",
            nameEn: "Malawi",
            nameDe: "Malawi",
            phoneCode: "+265"
        },
        {
            code: "MY",
            nameTr: "Malezya",
            nameEn: "Malaysia",
            nameDe: "Malaysia",
            phoneCode: "+60"
        },
        {
            code: "MV",
            nameTr: "Maldivler",
            nameEn: "Maldives",
            nameDe: "Malediven",
            phoneCode: "+960"
        },
        {
            code: "ML",
            nameTr: "Mali",
            nameEn: "Mali",
            nameDe: "Mali",
            phoneCode: "+223"
        },
        {
            code: "MT",
            nameTr: "Malta",
            nameEn: "Malta",
            nameDe: "Malta",
            phoneCode: "+356"
        },
        {
            code: "MR",
            nameTr: "Moritanya",
            nameEn: "Mauritania",
            nameDe: "Mauretanien",
            phoneCode: "+222"
        },
        {
            code: "MU",
            nameTr: "Mauritius",
            nameEn: "Mauritius",
            nameDe: "Mauritius",
            phoneCode: "+230"
        },
        {
            code: "MX",
            nameTr: "Meksika",
            nameEn: "Mexico",
            nameDe: "Mexiko",
            phoneCode: "+52"
        },
        {
            code: "MD",
            nameTr: "Moldova",
            nameEn: "Moldova",
            nameDe: "Moldawien",
            phoneCode: "+373"
        },
        {
            code: "MN",
            nameTr: "Moğolistan",
            nameEn: "Mongolia",
            nameDe: "Mongolei",
            phoneCode: "+976"
        },
        {
            code: "ME",
            nameTr: "Karadağ",
            nameEn: "Montenegro",
            nameDe: "Montenegro",
            phoneCode: "+382"
        },
        {
            code: "MS",
            nameTr: "Montserrat",
            nameEn: "Montserrat",
            nameDe: "Montserrat",
            phoneCode: "+1664"
        },
        {
            code: "MA",
            nameTr: "Fas",
            nameEn: "Morocco",
            nameDe: "Marokko",
            phoneCode: "+212"
        },
        {
            code: "MZ",
            nameTr: "Mozambik",
            nameEn: "Mozambique",
            nameDe: "Mosambik",
            phoneCode: "+258"
        },
        {
            code: "MM",
            nameTr: "Myanmar",
            nameEn: "Myanmar",
            nameDe: "Myanmar",
            phoneCode: "+95"
        },
        {
            code: "NA",
            nameTr: "Namibya",
            nameEn: "Namibia",
            nameDe: "Namibia",
            phoneCode: "+264"
        },
        {
            code: "NR",
            nameTr: "Nauru",
            nameEn: "Nauru",
            nameDe: "Nauru",
            phoneCode: "+674"
        },
        {
            code: "NP",
            nameTr: "Nepal",
            nameEn: "Nepal",
            nameDe: "Nepal",
            phoneCode: "+977"
        },
        {
            code: "NZ",
            nameTr: "Yeni Zelanda",
            nameEn: "New Zealand",
            nameDe: "Neuseeland",
            phoneCode: "+64"
        },
        {
            code: "NI",
            nameTr: "Nikaragua",
            nameEn: "Nicaragua",
            nameDe: "Nicaragua",
            phoneCode: "+505"
        },
        {
            code: "NE",
            nameTr: "Nijer",
            nameEn: "Niger",
            nameDe: "Niger",
            phoneCode: "+227"
        },
        {
            code: "NG",
            nameTr: "Nijerya",
            nameEn: "Nigeria",
            nameDe: "Nigeria",
            phoneCode: "+234"
        },
        {
            code: "KP",
            nameTr: "Kuzey Kore",
            nameEn: "North Korea",
            nameDe: "Nordkorea",
            phoneCode: "+850"
        },
        {
            code: "MK",
            nameTr: "Kuzey Makedonya",
            nameEn: "North Macedonia",
            nameDe: "Nordmazedonien",
            phoneCode: "+389"
        },
        {
            code: "NO",
            nameTr: "Norveç",
            nameEn: "Norway",
            nameDe: "Norwegen",
            phoneCode: "+47"
        },
        {
            code: "OM",
            nameTr: "Umman",
            nameEn: "Oman",
            nameDe: "Oman",
            phoneCode: "+968"
        },
        {
            code: "PK",
            nameTr: "Pakistan",
            nameEn: "Pakistan",
            nameDe: "Pakistan",
            phoneCode: "+92"
        },
        {
            code: "PW",
            nameTr: "Belau",
            nameEn: "Palau",
            nameDe: "Palau",
            phoneCode: "+680"
        },
        {
            code: "PS",
            nameTr: "Filistin",
            nameEn: "Palestine",
            nameDe: "Palästina",
            phoneCode: "+970"
        },
        {
            code: "PA",
            nameTr: "Panama",
            nameEn: "Panama",
            nameDe: "Panama",
            phoneCode: "+507"
        },
        {
            code: "PG",
            nameTr: "Papua Yeni Gine",
            nameEn: "Papua New Guinea",
            nameDe: "Papua-Neuguinea",
            phoneCode: "+675"
        },
        {
            code: "PY",
            nameTr: "Paraguay",
            nameEn: "Paraguay",
            nameDe: "Paraguay",
            phoneCode: "+595"
        },
        {
            code: "PE",
            nameTr: "Peru",
            nameEn: "Peru",
            nameDe: "Peru",
            phoneCode: "+51"
        },
        {
            code: "PH",
            nameTr: "Filipinler",
            nameEn: "Philippines",
            nameDe: "Philippinen",
            phoneCode: "+63"
        },
        {
            code: "PL",
            nameTr: "Polonya",
            nameEn: "Poland",
            nameDe: "Polen",
            phoneCode: "+48"
        },
        {
            code: "PT",
            nameTr: "Portekiz",
            nameEn: "Portugal",
            nameDe: "Portugal",
            phoneCode: "+351"
        },
        {
            code: "QA",
            nameTr: "Katar",
            nameEn: "Qatar",
            nameDe: "Katar",
            phoneCode: "+974"
        },
        {
            code: "RO",
            nameTr: "Romanya",
            nameEn: "Romania",
            nameDe: "Rumänien",
            phoneCode: "+40"
        },
        {
            code: "RU",
            nameTr: "Rusya",
            nameEn: "Russia",
            nameDe: "Russland",
            phoneCode: "+7"
        },
        {
            code: "RW",
            nameTr: "Ruanda",
            nameEn: "Rwanda",
            nameDe: "Ruanda",
            phoneCode: "+250"
        },
        {
            code: "KN",
            nameTr: "Saint Kitts and Nevis",
            nameEn: "Saint Kitts ve Nevis",
            nameDe: "St. Kitts und Nevis",
            phoneCode: "+1"
        },
        {
            code: "LC",
            nameTr: "Saint Lucia",
            nameEn: "Saint Lucia",
            nameDe: "St. Lucia",
            phoneCode: "+1758"
        },
        {
            code: "VC",
            nameTr: "Saint Vincent ve Grenadinler",
            nameEn: "Saint Vincent and the Grenadines",
            nameDe: "St. Vincent und die Grenadinen",
            phoneCode: "+1784"
        },
        {
            code: "SA",
            nameTr: "Suudi Arabistan",
            nameEn: "Saudi Arabia",
            nameDe: "Saudi-Arabien",
            phoneCode: "+966"
        },
        {
            code: "SN",
            nameTr: "Senegal",
            nameEn: "Senegal",
            nameDe: "Senegal",
            phoneCode: "+221"
        },
        {
            code: "RS",
            nameTr: "Sırbistan",
            nameEn: "Serbia",
            nameDe: "Serbien",
            phoneCode: "+381"
        },
        {
            code: "SC",
            nameTr: "Seyşeller",
            nameEn: "Seychelles",
            nameDe: "Seychellen",
            phoneCode: "+248"
        },
        {
            code: "SL",
            nameTr: "Sierra Leone",
            nameEn: "Sierra Leone",
            nameDe: "Sierra Leone",
            phoneCode: "+232"
        },
        {
            code: "SG",
            nameTr: "Singapur",
            nameEn: "Singapore",
            nameDe: "Singapur",
            phoneCode: "+65"
        },
        {
            code: "SK",
            nameTr: "Slovakya",
            nameEn: "Slovakia",
            nameDe: "Slowakei",
            phoneCode: "+421"
        },
        {
            code: "SI",
            nameTr: "Slovenya",
            nameEn: "Slovenia",
            nameDe: "Slowenien",
            phoneCode: "+386"
        },
        {
            code: "SB",
            nameTr: "Solomon Adaları",
            nameEn: "Solomon Islands",
            nameDe: "Salomonen",
            phoneCode: "+677"
        },
        {
            code: "SO",
            nameTr: "Somali",
            nameEn: "Somalia",
            nameDe: "Somalia",
            phoneCode: "+252"
        },
        {
            code: "ZA",
            nameTr: "Güney Afrika",
            nameEn: "South Africa",
            nameDe: "Südafrika",
            phoneCode: "+27"
        },
        {
            code: "KR",
            nameTr: "Güney Kore",
            nameEn: "South Korea",
            nameDe: "Südkorea",
            phoneCode: "+82"
        },
        {
            code: "SS",
            nameTr: "Güney Sudan",
            nameEn: "South Sudan",
            nameDe: "Südsudan",
            phoneCode: "+211"
        },
        {
            code: "ES",
            nameTr: "İspanya",
            nameEn: "Spain",
            nameDe: "Spanien",
            phoneCode: "+34"
        },
        {
            code: "LK",
            nameTr: "Sri Lanka",
            nameEn: "Sri Lanka",
            nameDe: "Sri Lanka",
            phoneCode: "+94"
        },
        {
            code: "SD",
            nameTr: "Sudan",
            nameEn: "Sudan",
            nameDe: "Sudan",
            phoneCode: "+249"
        },
        {
            code: "SR",
            nameTr: "Surinam",
            nameEn: "Suriname",
            nameDe: "Suriname",
            phoneCode: "+597"
        },
        {
            code: "SE",
            nameTr: "İsveç",
            nameEn: "Sweden",
            nameDe: "Schweden",
            phoneCode: "+46"
        },
        {
            code: "CH",
            nameTr: "İsviçre",
            nameEn: "Switzerland",
            nameDe: "Schweiz",
            phoneCode: "+41"
        },
        {
            code: "SY",
            nameTr: "Suriye",
            nameEn: "Syria",
            nameDe: "Syrien",
            phoneCode: "+963"
        },
        {
            code: "TW",
            nameTr: "Tayvan",
            nameEn: "Taiwan",
            nameDe: "Taiwan",
            phoneCode: "+886"
        },
        {
            code: "TJ",
            nameTr: "Tacikistan",
            nameEn: "Tajikistan",
            nameDe: "Tadschikistan",
            phoneCode: "+992"
        },
        {
            code: "TZ",
            nameTr: "Tanzanya",
            nameEn: "Tanzania",
            nameDe: "Tansania",
            phoneCode: "+255"
        },
        {
            code: "TH",
            nameTr: "Tayland",
            nameEn: "Thailand",
            nameDe: "Thailand",
            phoneCode: "+66"
        },
        {
            code: "BS",
            nameTr: "Bahamalar",
            nameEn: "The Bahamas",
            nameDe: "Die Bahamas",
            phoneCode: "+1242"
        },
        {
            code: "GM",
            nameTr: "Gambiya",
            nameEn: "The Gambia",
            nameDe: "Gambia",
            phoneCode: "+220"
        },
        {
            code: "TG",
            nameTr: "Togo",
            nameEn: "Togo",
            nameDe: "Togo",
            phoneCode: "+228"
        },
        {
            code: "TN",
            nameTr: "Tunus",
            nameEn: "Tunisia",
            nameDe: "Tunesien",
            phoneCode: "+216"
        },
        {
            code: "TM",
            nameTr: "Türkmenistan",
            nameEn: "Turkmenistan",
            nameDe: "Turkmenistan",
            phoneCode: "+993"
        },
        {
            code: "TC",
            nameTr: "Turks ve Caicos Adaları",
            nameEn: "Turks and Caicos Islands",
            nameDe: "Turks- und Caicosinseln",
            phoneCode: "+1649"
        },
        {
            code: "UG",
            nameTr: "Uganda",
            nameEn: "Uganda",
            nameDe: "Uganda",
            phoneCode: "+256"
        },
        {
            code: "UA",
            nameTr: "Ukrayna",
            nameEn: "Ukraine",
            nameDe: "Ukraine",
            phoneCode: "+380"
        },
        {
            code: "AE",
            nameTr: "Birleşik Arap Emirlikleri",
            nameEn: "United Arab Emirates",
            nameDe: "Vereinigte Arabische Emirate",
            phoneCode: "+971"
        },
        {
            code: "GB",
            nameTr: "Birleşik Krallık",
            nameEn: "United Kingdom",
            nameDe: "Vereinigtes Königreich",
            phoneCode: "+44"
        },
        {
            code: "US",
            nameTr: "Amerika Birleşik Devletleri",
            nameEn: "United States",
            nameDe: "Vereinigte Staaten von Amerika",
            phoneCode: "+1"
        },
        {
            code: "UY",
            nameTr: "Uruguay",
            nameEn: "Uruguay",
            nameDe: "Uruguay",
            phoneCode: "+598"
        },
        {
            code: "UZ",
            nameTr: "Özbekistan",
            nameEn: "Uzbekistan",
            nameDe: "Usbekistan",
            phoneCode: "+998"
        },
        {
            code: "VU",
            nameTr: "Vanuatu",
            nameEn: "Vanuatu",
            nameDe: "Vanuatu",
            phoneCode: "+678"
        },
        {
            code: "VE",
            nameTr: "Venezuela",
            nameEn: "Venezuela",
            nameDe: "Venezuela",
            phoneCode: "+58"
        },
        {
            code: "VN",
            nameTr: "Vietnam",
            nameEn: "Vietnam",
            nameDe: "Vietnam",
            phoneCode: "+84"
        },
        {
            code: "YE",
            nameTr: "Yemen",
            nameEn: "Yemen",
            nameDe: "Jemen",
            phoneCode: "+967"
        },
        {
            code: "ZM",
            nameTr: "Zambiya",
            nameEn: "Zambia",
            nameDe: "Sambia",
            phoneCode: "+260"
        },
        {
            code: "ZW",
            nameTr: "Zimbabve",
            nameEn: "Zimbabwe",
            nameDe: "Simbabwe",
            phoneCode: "+263"
        }
    ]
};

export default countryList;