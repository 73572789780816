<!-- 
NOTES
   1) Side widget not arrangable

-->

<template>
   <div id="dashboard-container">
      <!-- Without dispute, Dashboard is a product -->
      <!-- Main Widget Section -->
      <Aside />
      <Main>
         <Navbar />

         <Transition name="fade" mode="out-in">
            <AddWidget v-if="controller.status" />
         </Transition>
         <OtpModal></OtpModal>
         <div id="dashboard-widgets-wrapper">
            <div class="dashboard-widgets-row row">
               <div class="dashboard-main-widgets-wrapper col-12 col-xxl-9">
                  <!-- Header Row Common -->
                  <div class="dashboard-main-header-wrapper row">
                     <div class="col-12 col-lg-6">
                        <Shortcuts />
                     </div>
                     <div class="col-6 d-none d-lg-block">
                        <AccountCard :properties="widgets.side.balance" />
                     </div>
                  </div>
                  <!-- Header Row Common -->
                  <div class="row">
                     <div class="col-12 d-block d-sm-none">
                        <TargetAchivementVue class="mb-0 mt-4" v-if="isMobile" />
                     </div>
                  </div>

                  <!-- Main Widgets Arrangable Section -->
                  <div class="dashboard-main-widgets col-12">
                     <div class="dashboard-main-widgets-row row gy-4">
                        <template v-for="(widget, index) in widgets.main">
                           <div class="col-md-12 col-lg-6 col-12" :key="index" :class="widget.type == 3 ? 'col-lg-12' : 'col-md-6'">
                              <Widget :properties="widget" @dynamicFeed="handlerFetchWidget" @removeWidget="handlerRemoveWidget" />
                           </div>
                        </template>
                     </div>
                  </div>
               </div>

               <!-- Side Widgets Non-Arrangable Section -->
               <div class="dashboard-side-widgets-wrapper col-3 d-none d-xxl-flex">
                  <div class="dashboard-side-widgets">
                     <Campaign :properties="widgets.side.campaigns" />
                     <TargetAchivementVue v-if="!isMobile" />
                     <Widget :properties="widgets.side.announcements" side />
                  </div>
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import Shortcuts from "@/components/widgets/Shortcuts.vue";
import AccountCard from "@/components/widgets/AccountCard.vue";

import AddWidget from "@/components/structural/dashboard/AddWidget.vue";
import Widget from "@/components/widgets/base/Widget.vue";
import WidgetControls from "@/components/widgets/helpers/widget-helpers.js";
import { dashboardService } from "@/service/index.js";
import dummyResponse2 from "@/components/widgets/helpers/dummyResponse2";
import Campaign from "@/components/widgets/Campaign.vue";
import TargetAchivementVue from "@/pages/TargetAchivement.vue";

import OtpModal from "./OtpModal.vue";

export default {
   name: "Dasboard",
   components: {
      Aside,
      Navbar,
      Main,
      Shortcuts,
      AddWidget,
      Widget,
      AccountCard,
      OtpModal,
      Campaign,
      TargetAchivementVue,
   },
   data() {
      return {
         controller: {
            status: false,
            widgets: [],
         },
         widgets: {
            main: [],
            side: {
               announcements: {
                  name: "dashboardPage_announcements",
                  type: "announcement",
                  pending: true,
                  content: null,
               },
               campaigns: {
                  name: "dashboardPage_campaigns",
                  type: "campaign",
                  pending: true,
                  content: null,
               },
               balance: {
                  name: "Balance",
                  type: "balance",
                  pending: true,
                  content: null,
               },
            },
         },
         dragFrom: null,
         dropTo: null,
      };
   },

   created() {
      this.handlerGenerator(dummyResponse2, "reservations", false, 1, 0);
      this.handlerGenerator(dummyResponse2, "upcomings", false, 2, 1);
      this.handlerGenerator(dummyResponse2, "reportGiros", false, 3, 2);

      this.widgets.main.forEach((widget) => {
         widget.pending = true;
      });

      // clear widget status
      // this.widgets.main = [];
      // this.controller.widgets = [];

      //Generate Dashboard Widgets
      this.handlerGetReservations();
      this.handlerGetUpcomings();
      this.handlerGetReportGiros();
      this.handlerGetAnnouncements();
      this.handlerGetAgencyBalance();
   },
   computed: {
      isMobile() {
         return this.$store.state.app.clientInformations.isMobile;
      },
   },
   methods: {
      handlerGetReservations() {
         dashboardService.GetReservations({ widgetItem: 100, expiredFlights: false }).then((response) => {
            this.handlerGenerator(response, "reservations", false, 1, 0);
         });
      },
      handlerGetUpcomings() {
         dashboardService.GetUpcomings({ widgetItem: 200, onlyFltWksc: false }).then((response) => {
            this.$store.commit("app/setUpcomingsDate", response.dateInfo);
            this.handlerGenerator(response, "upcomings", false, 2, 1);
         });
      },
      handlerGetReportGiros() {
         dashboardService.GetReportGiros({ widgetItem: 300 }).then((response) => {
            this.$store.commit("app/setGirosGrandTotal", response.grandTotal);
            this.$store.commit("app/setGirosDate", response.dateInfo);
            this.handlerGenerator(response, "reportGiros", false, 3, 2);
         });
      },
      handlerGetAnnouncements() {
         dashboardService.GetAnnouncements({}).then((response) => {
            // announcements widget content
            this.widgets.side.announcements.pending = false;
            this.widgets.side.announcements.content = response.announcements;

            // campaigns widget content
            this.widgets.side.campaigns.pending = false;
            this.widgets.side.campaigns.content = response.campaigns;
         });
      },
      handlerGetAgencyBalance() {
         dashboardService.GetAgencyBalance({}).then((response) => {
            // balance widget content
            this.widgets.side.balance.pending = false;
            this.widgets.side.balance.content = response.agencyBalance;
         });
      },
      handlerGenerator(response, column, isPending, type, index) {
         const widget = WidgetControls.modelGenerator({
            name: this.$options.filters.widgets2(type),
            property: this.$options.filters.EDashMainWidgetType(type),
            type: type,
            order: index,
            privacy: false,
            get content() {
               return response[column];
            },
         });
         widget.pending = isPending ?? false;
         this.controller.widgets.push(type);
         this.$set(this.widgets.main, index, widget);
      },
      handlerFetchWidget(fetch) {
         this.widgets.main[fetch.order].content = fetch.content;
      },
      handlerRemoveWidget(type) {
         const query = {
            main: [],
            side: [],
         };

         this.widgets.main.forEach((widget) => {
            if (widget.type != type) {
               query.main.push(widget.type);
            }
         });
      },
   },
   watch: {
      dropTo: {
         handler: function () {
            const widgetA = this.widgets.main.findIndex((item) => item.type == this.dragFrom.type);
            const widgetB = this.widgets.main.findIndex((item) => item.type == this.dropTo.type);
            this.widgets.main[widgetA].order = widgetB;
            this.widgets.main[widgetB].order = widgetA;
            this.handlerSortWidgets("main");
         },
      },

      "$i18n.locale": {
         handler: function () {
            this.widgets.side.announcements.pending = true;
            this.widgets.side.campaigns.pending = true;
            this.handlerGetAnnouncements();
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#dashboard-container {
   #dashboard-widgets-wrapper {
      padding: 1rem 1rem;
      @media (min-width: 992px) {
         padding: 1rem 2rem;
      }

      .dashboard-main-widgets {
         margin-top: 1.5rem;
      }
      .dashboard-side-widgets {
         display: flex;
         flex-direction: column;
         width: 100%;
         position: sticky;
         top: 10px;
         height: fit-content;

         ::v-deep {
            .widget-base-container {
               height: auto;
               margin-bottom: 1.5rem;

               .widget-header-container {
                  .drag-widget,
                  .toggle-informations {
                     display: none !important;
                  }
               }

               .widget-body-container {
                  padding-left: 0.75rem;
                  padding-right: 0.75rem;
               }
            }
         }
      }
      .dashboard-widgets-row {
      }
   }
}
</style>

<style lang="scss">
#dashboard-container {
   .border {
      padding: 0.5rem;
      // border: 2px dotted #ccc !important;
   }
}
</style>
