<template>
   <div class="widget-multi-table-container">
      <Cards :status="properties?.extras" :rows="properties?.content" />

      <!-- Multiple Table Header  -->
      <Section class="multiple-table-header-wrapper" v-if="properties?.multitable?.status">
         <div class="multi-table-tabs">
            <template v-for="(tab, index) in properties.multitable?.tabs">
               <div
                  class="multi-table-tab"
                  :key="index"
                  :class="{ 'multi-table-active-tab': index === mode, 'disabled-tab': progress }"
                  @click="handlerSwitchTab(index, tab.widgetItem)">
                  {{ $t(tab.name) }}
               </div>
            </template>
         </div>
      </Section>
      <Table
         :properties="properties"
         :rows="properties?.content"
         :loading="progress"
         :resetPagination="resetPagination"
         @expiredFlights="
            () => {
               this.expiredFlights = !this.expiredFlights;
               handlerSwitchTab(mode, properties.multitable.tabs[mode].widgetItem, true);
            }
         "
         @onlyFltWksc="
            () => {
               this.onlyFltWksc = !this.onlyFltWksc;
               handlerSwitchTab(mode, properties.multitable.tabs[mode].widgetItem, true);
            }
         "
         @resetPagination="resetPagination = !resetPagination"
         :activeTab="activeTab" />
   </div>
</template>

<script>
import WidgetControls from "@/components/widgets/helpers/widget-helpers.js";
import { dashboardService } from "@/service/index.js";
import Cards from "@/components/widgets/Extras/Cards.vue";
import Table from "@/components/widgets/Table.vue";
export default {
   Name: "MultiTable",
   props: {
      properties: [Object, Array],
   },
   components: {
      Table,
      Cards,
   },
   data() {
      return {
         search: "",
         mode: 0,
         tabs: [],
         progress: false,
         expiredFlights: false,
         onlyFltWksc: false,
         activeTab: this.properties?.multitable?.tabs[0].widgetItem,
         resetPagination: false,
      };
   },
   created() {},

   methods: {
      handlerSwitchTab(index, widgetItem, bypass) {
         if (this.activeTab != widgetItem || bypass) {
            if (widgetItem != 101) {
               this.expiredFlights = false;
            }
            if (widgetItem != 201) {
               this.onlyFltWksc = false;
               this.$store.commit("app/setOnlyFltWksc", this.onlyFltWksc);
            }
            this.progress = true;
            this.mode = index;
            this.activeTab = widgetItem;
            const service = this.properties.multitable.service;
            const responseObj = this.properties.multitable.name;
            const params = {
               widgetItem: widgetItem,
            };
            if (widgetItem == 101) {
               params.expiredFlights = this.expiredFlights;
            }
            if (widgetItem == 201) {
               this.$store.commit("app/setOnlyFltWksc", this.onlyFltWksc);
               params.onlyFltWksc = this.onlyFltWksc;
            }
            dashboardService[service](params).then((response) => {
               this.resetPagination = true;
               this.progress = false;
               this.$parent.$emit("dynamicFeed", {
                  order: this.properties.order,

                  content: WidgetControls.DynamicFeed(response[responseObj]),
               });
            });
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.widget-multi-table-container {
   section.multiple-table-header-wrapper {
      //       border: 1px solid #ccc;
      display: flex;
      justify-content: space-between;
      margin: 0.5rem 0;
      margin-bottom: 2rem;
      height: fit-content;
      .report-multi-table-info-container {
         display: flex;
         flex-direction: column;
         height: 20px;
         width: 190px;
         align-items: flex-end;
         span {
            display: flex;
            justify-content: center;
            font-weight: 600;
            font-size: var(--normal);
         }
         .gts-tooltip {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20%;
            padding: 0rem !important;
            z-index: 1;
            ::v-deep {
               i.gts-icon {
                  width: 32px !important;
                  height: 32px !important;
               }
            }
         }
      }
      .multi-table-tabs {
         display: flex;
         align-items: center;
         background: #f2f2f2;
         border-radius: 8px;
         height: 40px;
         @media (max-width: 576px) {
            width: 100%;
         }

         .multi-table-tab {
            padding: 0.25rem 0.75rem;
            margin-right: 0.5rem;
            font-weight: 400;
            color: #666666;
            height: 32px;
            cursor: pointer;
            display: flex;
            align-items: center;

            &.multi-table-active-tab {
               color: #ffffff;
               font-weight: 500;
               background-color: #2e3331;
               border-radius: 8px;

               small {
                  background-color: #fff;
               }
            }
            &.disabled-tab {
               pointer-events: none;
            }
         }
      }
      .multi-table-search {
         input {
            width: 140px;
            border-radius: 24rem;
            outline: 0;
            border: 2px solid #ccc;
            height: 40px;
            text-indent: 1rem;
            color: #363636;
            text-transform: uppercase;
            font-size: var(--small);
            background-color: #f6f6f6;

            &:focus {
               border-color: var(--accent-primary-lighten);
               background-color: #98dcff14;
               font-weight: 500;
            }

            &::-webkit-input-placeholder {
               text-transform: capitalize;
            }
         }
      }
   }
}
</style>

<style lang="scss">
.widget-multi-table-container {
   .tabs {
      display: none !important;
   }
}
</style>
