<template>
   <div class="addon-card">
      <div class="image-section">
         <img :src="currentImageUrl" alt="Addon Image" />
         <div v-if="addOn.image1Url && addOn.image2Url" class="navigation-buttons">
            <button class="btn-prev" @click="previousImage">
               <i class="gts-icon icon-arrow icon-sm"></i>
            </button>
            <button class="btn-next" @click="nextImage">
               <i class="gts-icon icon-arrow icon-sm"></i>
            </button>
         </div>
      </div>
      <div class="content-section">
         <div class="header">
            <h3>{{ addOn.title }}</h3>
            <span class="tag" :class="{ mandatory: addOn.required, 'non-mandatory': !addOn.required }">
               {{ addOn.required ? $t("hotelCheckout_addOnMandatory") : $t("hotelCheckout_addOnNonMandatory") }}
            </span>
         </div>

         <div class="description">
            <template v-if="descriptionAsList.length">
               <ul>
                  <li v-for="(item, index) in visibleDescriptionAsList" :key="index">{{ item }}</li>
               </ul>
            </template>
            <template v-else>
               {{ isExpanded ? addOn.description : truncatedDescription }}
            </template>
         </div>

         <div class="restriction">
            <span v-if="shouldShowReadMore" class="read-more" @click.prevent="toggleDescription">
               {{ isExpanded ? $t("hotelCheckout_addOnReadLess") : $t("hotelCheckout_addOnReadMore") }}
            </span>
            <template v-for="(item, itemIndex) in addOn.disabledChildAges">
               <div class="age-restriction" v-if="addOn.disabledChildAges[0].length == 0 ? false : true" :key="itemIndex">
                  <i class="gts-icon icon-info-circle-orange icon-sm"></i>
                  <span>{{ handlerInformationText(item) }}</span>
               </div>
            </template>
         </div>
      </div>
      <div class="details-section">
         <div class="price">{{ $options.filters.price(addOn.price, addOn.currency) }}</div>
         <div class="date">{{ AddOnDate }}</div>
         <div class="guests" v-if="inBasket || addOn.required">
            <span>{{ $t("hotel_lblAdult") }} : {{ adultCount }}</span>
            <span>{{ $t("hotel_lblChild") }} : {{ childCount }}</span>
         </div>
         <UIButton
            v-if="!addOn.required && !inBasket"
            className="pink"
            :notRounded="true"
            :text="$t('hotelCheckout_addOnInsert')"
            @click.native="handlerBasket"></UIButton>
         <UIButton
            v-show="!addOn.required && inBasket"
            className="warning"
            :notRounded="true"
            :text="$t('hotelCheckout_addOnRemove')"
            @click.native="handlerBasket()"></UIButton>
      </div>
   </div>
</template>

<script>
import moment from "moment";
import UIButton from "../unitary/Button.vue";

export default {
   name: "AddonCard",
   components: {
      UIButton,
   },
   props: {
      roomIdentifier: { type: String },
      guest: { type: Array },
      addOn: {
         type: Object,
      },
   },
   data() {
      return {
         isExpanded: false,
         characterLimit: 400,
         inBasket: false,
         adultCount: 0,
         childCount: 0,
         currentImageIndex: 0,
      };
   },
   methods: {
      calculateCounts() {
         this.childCount = 0;
         this.adultCount = 0;

         this.adultCount = this.guest.filter((i) => i.type == 1).length;
         this.childCount = this.guest.filter((i) => i.type == 2).length;

         this.childs = this.guest.filter((i) => i.type == 2);

         var childAges = [];
         this.childs.forEach((i) => {
            const age = moment().diff(moment(i.minDate, "YYYY-MM-DD"), "years");
            childAges.push(age);
         });

         const disabledAges = this.addOn.disabledChildAges.filter((r) => r.disabled);
         disabledAges.forEach((disabledRestriction) => {
            childAges.forEach((age) => {
               if (disabledRestriction.fromAge <= age && disabledRestriction.toAge >= age) {
                  this.childCount--;
               }
            });
         });
      },
      getAddons() {
         const basketResponse = JSON.parse(JSON.stringify(this.$store.state.hotel.basketResponse));

         const room = basketResponse.rooms?.find((room) => room.roomIdentifier === this.roomIdentifier);

         if (room) {
            const addOn = room.addOns?.find((addOn) => addOn.id === this.addOn.id);

            if (addOn) {
               addOn.selected = !addOn.selected;
            }
         }

         const selectedAddOns = {};

         basketResponse.rooms.forEach((room) => {
            const selectedAddOnIds = room.addOns?.filter((addOn) => addOn.required === true).map((addOn) => addOn.id);

            if (selectedAddOnIds && selectedAddOnIds.length > 0) {
               selectedAddOns[room.roomIdentifier] = selectedAddOnIds;
            }
         });

         this.$emit("startupGetAddons", selectedAddOns);
      },
      handlerBasket() {
         const basketResponse = JSON.parse(JSON.stringify(this.$store.state.hotel.basketResponse));
         const room = basketResponse.rooms?.find((room) => room.roomIdentifier === this.roomIdentifier);
         if (room) {
            const addOn = room.addOns?.find((addOn) => addOn.id === this.addOn.id);
            if (addOn) {
               addOn.selected = !addOn.selected;
            }
         }
         const selectedAddOns = {};
         basketResponse.rooms.forEach((room) => {
            const selectedAddOnIds = room.addOns
               ?.filter((addOn) => addOn.selected) // selected true olanları filtrele
               .map((addOn) => addOn.id); // sadece ID'lerini al

            if (selectedAddOnIds && selectedAddOnIds.length > 0) {
               selectedAddOns[room.roomIdentifier] = selectedAddOnIds;
            }
         });

         this.$emit("handleUpdateAddons", selectedAddOns);
         this.inBasket = !this.inBasket;
      },
      toggleDescription() {
         this.isExpanded = !this.isExpanded;
      },
      previousImage() {
         if (this.currentImageIndex === 0) {
            this.currentImageIndex = 1; // Switch to second image
         } else {
            this.currentImageIndex = 0; // Switch back to first image
         }
      },
      nextImage() {
         if (this.currentImageIndex === 0) {
            this.currentImageIndex = 1; // Switch to second image
         } else {
            this.currentImageIndex = 0; // Switch back to first image
         }
      },
      handlerInformationText(obj) {
         const ageRange = `${obj.fromAge}-${obj.toAge}`;
         var msg;

         if (obj.disabled) {
            msg = this.$t("hotelcheckout_addOnCardWarning").replace("#VALUE#", ageRange);
         }
         if (obj.free) {
            msg = this.$t("hotelcheckout_addOnCardfree").replace("#AGE_RANGE#", ageRange);
         }

         return msg;
      },
   },
   created() {
      this.getAddons();
   },
   mounted() {
      this.calculateCounts();
   },
   computed: {
      currentImageUrl() {
         return this.currentImageIndex === 0 ? this.addOn.image1Url : this.addOn.image2Url;
      },
      AddOnDate() {
         return moment(this.addOn.effectiveDate).format("DD.MM.YYYY");
      },
      truncatedDescription() {
         return this.addOn.description.length > this.characterLimit
            ? this.addOn.description.substring(0, this.characterLimit) + "..."
            : this.addOn.description;
      },
      descriptionAsList() {
         return this.addOn.description.includes("\n") ? this.addOn.description.split("\n") : [];
      },
      visibleDescriptionAsList() {
         const combinedLength = this.descriptionAsList.join("").length;
         if (!this.isExpanded && combinedLength > this.characterLimit) {
            let charCount = 0;
            return this.descriptionAsList.filter((item) => {
               charCount += item.length;
               return charCount <= this.characterLimit;
            });
         }
         return this.descriptionAsList;
      },
      shouldShowReadMore() {
         return this.addOn.description.length > this.characterLimit || this.descriptionAsList.join("").length > this.characterLimit;
      },
   },
};
</script>

<style scoped lang="scss">
.addon-card {
   display: flex;
   border: 2px solid #f3cfff;
   padding: 1rem;
   border-radius: 8px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
   margin: 1rem 0;
   @media (max-width: 576px) {
      flex-direction: column;
   }

   .image-section {
      flex: 1;
      position: relative;
      height: fit-content;
      @media (max-width: 576px) {
         display: none;
      }
      .btn-next {
         margin-right: 0.5rem;
      }
      .btn-prev {
         i {
            transform: rotate(180deg);
         }
      }
      img {
         width: 100%;
         border-radius: 8px;
      }
      .navigation-buttons {
         position: absolute;
         top: 40%;
         left: 0;
         right: 0;
         display: flex;
         justify-content: space-between;
         button {
            border-radius: 12px;
            border: none;
            padding: 0.5rem;
            cursor: pointer;
            margin-left: 0.5rem;
         }
      }
   }

   .content-section {
      flex: 2.5;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .header {
         display: flex;
         align-items: center;

         h3 {
            margin: 0;
            font-size: 1.2rem;
            font-weight: bold;
         }

         .tag {
            padding: 0.2rem 0.5rem;
            border-radius: 8px;
            margin-left: 0.5rem;
            font-size: 0.9rem;
         }

         .mandatory {
            background-color: #fceaff;
            color: #ff00ff;
         }

         .non-mandatory {
            background-color: #ffe5f7;
            color: #ff00ff;
         }
      }

      .description {
         margin: 0.5rem 0;
         font-size: 0.95rem;
         color: #666;
         overflow: hidden;
         word-break: break-word;

         ul {
            list-style-type: disc;
            padding-left: 20px;
         }

         li {
            margin-bottom: 0.5rem;
         }
      }

      .restriction {
         display: flex;
         flex-direction: column;
         max-width: 70%;
         margin-top: auto;
         .read-more {
            color: #000;
            font-weight: bold;
            cursor: pointer;
            margin-top: auto;
            text-decoration: underline;
            margin-bottom: 0.5rem;
         }
         .age-restriction {
            display: flex;
            max-width: 100%;
            align-items: center;
            font-size: 0.75rem;
            color: #ff6a00;
            border: 1px solid #ff990086;
            margin-top: auto;
            border-radius: 0.75rem;
            background: #ffe6000c;
            &:not(:first-child) {
               margin-top: 0.25rem;
            }
            i {
               margin: 0.25rem;
            }
         }
      }
   }

   .details-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 2px solid #f3cfff;
      gap: 0.5rem;
      .guests {
         display: flex;
         flex-direction: column;
         text-align: center;
         align-items: flex-start;
         span {
            font-size: 1.1rem;
            font-weight: 600;
            color: #59008d;
         }
      }
      .price {
         font-size: 1.4rem;
         font-weight: 600;
         color: #c900cf;
         text-align: center;
      }

      .date {
         background-color: #fceaff;
         color: #c900cf;
         padding: 0.25rem 1rem 0.25rem 1rem;
         border-radius: 8px;
         font-size: 1.25rem;
         font-weight: 600;
         text-align: center;
      }

      button {
         height: 32px !important;
         border-radius: 0.75rem !important;
         max-width: 70% !important;
      }
   }
}
</style>
