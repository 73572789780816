<template>
   <div class="pax-row-container" ref="paxRow">
      <section class="customer-management-wrapper" v-if="showFihrist">
         <CustomerManagement ref="customerListModal" @set-customer="handlerSetCustomer" show-close />
      </section>
      <!-- {{ model.passport }} -->
      <!-- {{ model }} -->
      <!-- {{ customerEdit }}
      <br />
      <br /> -->

      <!-- <CustomerManagement @set-customer="handlerSetCustomer" /> -->
      <div class="pax-row-inner-wrapper">
         <!-- PAX HEADER WITH PAX TYPE -->
         <div class="pax-row-header">
            {{ model.paxIndex }}. {{ $t("common_passenger") }}
            {{ model.paxType | EFltPaxType }}
         </div>

         <div class="pax-row-basic">
            <div class="pax-row-wrapper">
               <!-- Gender Selection -->
               <div class="pax-column">
                  <UISelectBox :options="GenderList" v-model="model.gender" light-theme />
               </div>

               <!-- Pax Name -->
               <div class="pax-column">
                  <UIInput
                     v-model="model.name"
                     @set-customer="handlerSetCustomer"
                     :customers="customerList"
                     ac-tag="name"
                     :class="inputsDisabled ? 'pointer-event-none' : ''"
                     :placeholder="$t('common_name')"
                     autocomplete
                     v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
               </div>

               <!-- Pax Surname -->
               <div class="pax-column">
                  <UIInput
                     v-model="model.surname"
                     @set-customer="handlerSetCustomer"
                     :customers="customerList"
                     ac-tag="surname"
                     :class="inputsDisabled ? 'pointer-event-none' : ''"
                     :placeholder="$t('common_surname')"
                     autocomplete
                     v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
               </div>

               <!-- Pax BIRTHDATE -->
               <div class="pax-column">
                  <UIDatePicker
                     v-model="model.birthdate"
                     :placeholder="$t('common_birthday')"
                     :min="DatePickerControl.min"
                     :max="DatePickerControl.max"
                     :disabled="inputsDisabled"
                     v-input="{ type: 'date', required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
               </div>

               <!-- Nationality Selection -->
               <div class="pax-column">
                  <UISelectBox
                     :options="CountryList"
                     v-model="model.nationality"
                     :class="inputsDisabled ? 'pointer-event-none' : ''"
                     allow-search
                     has-icons
                     light-theme />
               </div>

               <div class="pax-column pax-action">
                  <template v-if="model.name != null && !customerEdit.status">
                     <button class="pax-row-add-customer" @click="handlerAddNewCustomer" v-if="PaxAction(model)">
                        <i class="gts-icon icon-user-add"></i>
                     </button>
                  </template>

                  <template v-if="customerEdit.status">
                     <button class="pax-row-add-customer" @click="handlerEditNewCustomer">
                        <i class="gts-icon icon-user-edit"></i>
                     </button>
                  </template>

                  <template>
                     <button class="pax-row-add-customer" @click="triggerCustomerListModal">
                        <i class="gts-icon icon-user-search"></i>
                     </button>
                  </template>

                  <button class="pax-row-detail-toggle" @click="details = !details">
                     <i data-v-4580be98="" class="gts-icon icon-arrow-down icon-xs"></i>
                  </button>
               </div>
            </div>
         </div>

         <!-- CONDITIONAL PAX ROWS -->
         <div class="pax-row-detail bu-1">
            <div class="pax-row-wrapper">
               <template v-if="Inputs.foid || Inputs.docs">
                  <!-- Passport Number -->
                  <div class="pax-column">
                     <UIInput
                        v-model="model.passport.number"
                        :class="inputsDisabled ? 'pointer-event-none' : ''"
                        :placeholder="$t('passengerCard_passportNumber')"
                        v-input="{ type: 'alphanumeric', len: [2, 20], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                  </div>

                  <!-- Date Of Expiry -->
                  <div class="pax-column" v-if="Inputs.docs">
                     <UIDatePicker
                        v-model="model.passport.expiry"
                        :class="inputsDisabled ? 'pointer-event-none' : ''"
                        :placeholder="$t('passengerCard_expiryDate')"
                        :min="LastFlightDate"
                        v-input="{ type: 'date', required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                  </div>
               </template>

               <template v-if="model.nationality == 'TR'">
                  <!-- Hes Code -->
                  <div class="pax-column" v-if="Inputs.hesCode">
                     <UIInput
                        v-upper
                        v-model="model.hesCode"
                        :class="inputsDisabled ? 'pointer-event-none' : ''"
                        :placeholder="$t('passengerCard_hesCode')" />
                  </div>

                  <!-- TC No-->
                  <div class="pax-column" v-if="Inputs.tckn">
                     <UIInput
                        v-model="model.nationalIdentity"
                        :class="inputsDisabled ? 'pointer-event-none' : ''"
                        :placeholder="$t('passengerCard_identityNo')"
                        v-input="{ type: 'tckn', len: [11, 11], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                  </div>
               </template>
            </div>
         </div>

         <!-- PAX ROW DETAILS -->
         <Transition name="slide">
            <div class="pax-row-detail" v-if="details">
               <div class="pax-row-wrapper">
                  <!-- FOID FALSE -->
                  <template v-if="Inputs.foid ? false : true || Input.docs ? false : true">
                     <!-- Passport Number -->
                     <div class="pax-column">
                        <UIInput
                           v-model="model.passport.number"
                           :class="inputsDisabled ? 'pointer-event-none' : ''"
                           :placeholder="$t('passengerCard_passportNumber')"
                           v-input="{ type: 'alphanumeric', len: [2, 20], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                     </div>
                     <!-- Date Of Expiry -->

                     <div class="pax-column">
                        <UIDatePicker
                           v-model="model.passport.expiry"
                           :class="inputsDisabled ? 'pointer-event-none' : ''"
                           :placeholder="$t('passengerCard_expiryDate')"
                           :min="LastFlightDate"
                           v-input="{ type: 'date', required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                     </div>
                  </template>
                  <!-- false all show -->
                  <template v-if="!Inputs.foid && !Inputs.docs">
                     <!-- Passport Number -->
                     <div class="pax-column">
                        <UIInput
                           v-model="model.passport.number"
                           :class="inputsDisabled ? 'pointer-event-none' : ''"
                           :placeholder="$t('passengerCard_passportNumber')"
                           v-input="{ type: 'alphanumeric', len: [2, 20], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
                     </div>
                     <!-- Date Of Expiry -->
                     <div class="pax-column">
                        <UIDatePicker
                           v-model="model.passport.expiry"
                           :class="inputsDisabled ? 'pointer-event-none' : ''"
                           :placeholder="$t('passengerCard_expiryDate')"
                           :min="LastFlightDate"
                           v-input="{ type: 'date', required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
                     </div>
                  </template>

                  <!-- TC -->
                  <template v-if="model.nationality == 'TR' && Inputs.tckn == false">
                     <!-- Hes Code -->
                     <div class="pax-column">
                        <UIInput
                           v-model="model.hesCode"
                           :class="inputsDisabled ? 'pointer-event-none' : ''"
                           :placeholder="$t('passengerCard_hesCode')" />
                     </div>

                     <!-- TC No-->
                     <div class="pax-column">
                        <UIInput
                           v-model="model.nationalIdentity"
                           :class="inputsDisabled ? 'pointer-event-none' : ''"
                           v-numeric
                           :placeholder="$t('passengerCard_identityNo')"
                           v-input="{ type: 'tckn', len: [11, 11], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                     </div>
                  </template>
               </div>
            </div>
         </Transition>
      </div>
   </div>
</template>

<script>
// import CustomerList from "@/components/structural/flight/common/CustomerList.vue";
import Popup from "@/gts-popup.js";
import CustomerManagement from "@/components/structural/flight/common/CustomerManagement.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UIDatePicker from "@/components/unitary/form2/UIDatePicker2.vue";
import moment from "moment";
import { customerService } from "@/service/index.js";
// import debounce from "lodash.debounce";

import { isObjEmpty } from "@/helper/common";
export default {
   name: "PaxRow",
   components: {
      UISelectBox,
      UIInput,
      UIDatePicker,
      // CustomerList,
      CustomerManagement,
   },
   props: {
      model: Object,
   },
   data() {
      return {
         lastFlightDate: null,
         details: false,
         customerList: [],
         showFihrist: false,
         customerEdit: {
            status: false,
            watcher: null,
         },
         inputsDisabled: false,
      };
   },
   created() {
      const self = this;
      customerService.listCustomers({ status: 1 }).then((res) => {
         self.customerList = res.customers;
      });
   },
   computed: {
      GenderList() {
         const self = this;
         return [
            { name: self.$i18n.t("common_male"), value: 1 },
            { name: self.$i18n.t("common_female"), value: 2 },
         ];
      },
      CountryList() {
         const self = this;
         //mapping countrylist for select array [tr]
         let activeLanguage = self.$store.state.user.user.language;

         let selectorStr = "";
         switch (activeLanguage) {
            case 1:
               selectorStr = "Tr";
               break;

            case 2:
               selectorStr = "En";
               break;

            case 3:
               selectorStr = "De";
               break;

            default:
               selectorStr = "En";
               break;
         }

         return self.$store.state.app.countryList.map((data) => {
            var obj = {
               name: data[`name${selectorStr}`],
               value: data.code.toUpperCase(),
               icon: data.code,
            };
            return obj;
         });
      },
      DatePickerControl() {
         const self = this;

         switch (self.model.paxType) {
            case 2:
               //child
               return {
                  min: moment(self.lastFlightDate).subtract(12, "years").add(1, "day").format(this.$constants.dateFormat),
                  max: moment(self.lastFlightDate).subtract(2, "years").subtract(1, "day").format(this.$constants.dateFormat),
               };
            case 3:
               //infant
               return {
                  min: moment(self.lastFlightDate).subtract(2, "years").add(1, "day").format(this.$constants.dateFormat),
                  max: moment(new Date()).subtract(1, "day").format(this.$constants.dateFormat),
               };

            default:
               return {
                  min: moment(self.lastFlightDate).subtract(100, "years").format(this.$constants.dateFormat),
                  max: moment(self.lastFlightDate).subtract(12, "years").subtract(1, "day").format(this.$constants.dateFormat),
               };
         }
      },
      LastFlightDate() {
         const self = this;
         return moment(self.lastFlightDate).format(this.$constants.dateFormat);
      },
      Inputs() {
         const self = this;
         return self.$store.state.flight.checkout.response.inputs;
      },
      Validation() {
         return {
            name: true,
            surname: true,
            birthdate: true,
            tckn: this.model.nationality == "TR" ? true : false,
         };
      },
   },
   methods: {
      PaxAction(model) {
         const modelControl = {
            name: model?.name && model.name.length > 0 ? true : false,
            surname: model?.surname && model.surname.length > 0 ? true : false,
            birthdate: model?.birthdate && model.birthdate.length > 0 ? true : false,
         };

         if (modelControl.name && modelControl.surname && model.birthdate) {
            return true;
         } else {
            return false;
         }
      },
      triggerCustomerListModal() {
         const self = this;
         self.showFihrist = true;
      },
      handlerAddNewCustomer() {
         const self = this;

         const modelRowObj = {
            agencyId: this.$store.state.user.user.agencyId,
            agencyName: this.$store.state.user.user.agencyName,
            status: 1,
            gender: this.model.gender,
            birthdate: this.model.birthdate,
            name: this.model.name?.alphabetic(),
            surname: this.model.surname?.alphabetic(),
            email: null,
            phone: {
               iso: null,
               country: null,
               city: null,
               number: null,
            },
            nationality: this.model.nationality,
            nationalIdentity: null,
            passport: this.model.passport,
            milesPan: this.model.milesPan,
            hesCode: this.model.hesCode,
            id: 0,
            dbState: 1,
            insertedUserId: null,
            insertedUserEmail: null,
            insertedDatetime: null,
            updatedUserId: null,
            updatedUserEmail: null,
            updatedDatetime: null,
         };

         Popup.confirm(self.$i18n.t("common_information"), self.$i18n.t("customerManagement_popupNewCustomerWillBeAdded"));
         self.$nextTick(() => {
            document.body.querySelector("#popup-ok").addEventListener("click", function () {
               customerService.setCustomer(modelRowObj).then((response) => {
                  if (response.result.success) {
                     Popup.success(self.$i18n.t("common_success"), self.$i18n.t("customerManagement_popupCustomerAddedSubtitle"));
                  }
               });
            });
         });
      },
      handlerEditNewCustomer() {
         const customer = this.model;

         this.showFihrist = true;
         this.$nextTick(() => {
            this.$refs.customerListModal.selectedCustomer = customer;
            this.$refs.customerListModal.activeTab = "set";
         });
      },
      handlerSetCustomer(customer) {
         const self = this;

         if (this.customerEdit.watcher != null) {
            this.customerEdit.watcher();
         }

         self.model.id = customer.id;
         self.model.gender = customer.gender;
         self.model.birthdate = customer.birthdate != null ? moment(customer.birthdate).format(this.$constants.dateFormat) : null;
         self.model.name = customer.name;
         self.model.surname = customer.surname;
         self.model.nationality = customer.nationality;
         self.model.nationalIdentity = customer.nationalIdentity;
         self.model.passport = customer.passport;
         self.model.passport.serial = null;
         if (self.model.passport != null) {
            self.model.passport.country = customer.nationality;
         }
         self.model.hesCode = customer.hesCode;
         self.model.loyaltyCards = customer.loyaltyCards;
         self.model.phone = customer.phone;
         self.model.email = customer.email;

         self.customerEdit.status = true;

         self.handlerFillCustomer();

         this.customerEdit.watcher = this.$watch(
            "model",
            () => {
               if (self.model.phone?.country == null) {
                  self.customerEdit.status = true;
               }

               //else {
               //    self.customerEdit.status = false;
               // }

               // console.log("model customerEditWatch");
            },
            { deep: true }
         );
      },
      handlerFillCustomer() {
         const self = this;
         if (self.model.name != "" || self.model.surname != "") {
            self.model.name;
            self.model.surname;

            const paxObj = {
               paxIndex: this.model.paxIndex,
               paxType: this.model.paxType,
               gender: this.model.gender,
               birthdate: this.model.birthdate,
               name: this.model.name,
               surname: this.model.surname,
               nationality: this.model.nationality,
               nationalIdentity: this.model.nationalIdentity,
               passport: this.model.passport,
               hesCode: this.model.hesCode,
               loyaltyCards: this.model.loyaltyCards,
               email: this.model.email,
               phone: this.model.phone,
            };

            if (paxObj.passport != null) {
               paxObj.passport.country = this.model.nationality;
            }
            if (paxObj.passport != null) {
               paxObj.passport.country = this.model.nationality;
            }

            self.$store.commit("flight/setCheckoutPassenger", paxObj);
         }
      },
   },

   watch: {
      model: {
         handler: function (model) {
            this.handlerFillCustomer();

            if (model.name) {
               this.$emit("active-row", this.model);
            }
         },
         immediate: true,
         deep: true,
      },
      //Datepicker Controll
      "$store.state.flight.checkout.response.legs": {
         handler: function (legs) {
            if (!isObjEmpty(legs)) {
               let legCount = Object.keys(legs).length;
               this.lastFlightDate = legs[legCount].date;
            }
         },
         immediate: true,
         deep: true,
      },
      "$store.state.flight.checkout.isPriceDifference": {
         handler: function (val) {
            if (val) {
               this.inputsDisabled = true;
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.pax-row-container {
   display: flex;
   flex-direction: column;
   position: relative;
   margin: 0.5rem 0;

   .customer-management-wrapper {
      position: absolute;
      z-index: 99;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 1rem;
      right: 0;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

      @media (min-width: 992px) {
         width: 800px;
      }
   }
   .pax-row-inner-wrapper {
      background: #f8f8f8;
      border: 1px solid #ccc;
      margin: 0.5rem 0;
      padding: 1rem 0.5rem;
      padding-top: 1.5rem;
      border-radius: 0.5rem;
      position: relative;

      .pax-row-header {
         position: absolute;
         top: -1rem;
         left: 1rem;
         background: #fff;
         border: 1px solid #ccc;
         border-radius: 16px;
         padding: 0.25rem 1rem;
         font-size: var(--small);
         font-weight: 600;
      }

      .pax-row-basic,
      .pax-row-detail {
         .pax-row-wrapper {
            display: grid;
            grid-template-columns: 1fr;
            padding: 0.75rem 0;

            @media (min-width: 576px) {
               grid-template-columns: repeat(5, calc(100% / 5.7)) 10%;
            }
            @media (min-width: 768px) {
               grid-template-columns: repeat(3, 1fr);
               grid-row-gap: 1rem;
            }
            @media (min-width: 1200px) {
               grid-template-columns: repeat(5, calc(100% / 5.7)) 12%;
            }
            .pax-column {
               position: relative;
               margin: 0 0.5rem;
               margin-bottom: 0.5rem;

               @media (min-width: 576px) {
                  margin-bottom: 0rem;
               }

               .pax-row-detail-toggle {
                  border-radius: 50%;
                  width: 38px;
                  height: 38px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-shrink: 0;
               }
               .pax-row-add-customer {
                  background: none !important;
               }

               &.pax-action {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  margin-left: 0.5rem;
                  // background: #ccc;

                  @media (min-width: 576px) {
                     justify-content: space-around;
                     width: 100%;
                  }

                  @media (min-width: 768px) {
                     justify-content: flex-end;
                     padding-right: 1rem;
                     width: 100%;
                  }
                  @media (min-width: 992px) {
                     justify-content: flex-end;
                     padding-right: 0;
                     width: 100%;
                  }
               }
            }
         }
      }
   }
}
</style>
