<template>
   <div id="rent-a-car-driver-informations-container" class="gts-box">
      <section class="customer-management-wrapper" v-if="showFihrist">
         <CustomerManagement ref="customerListModal" @set-customer="handlerSetCustomer" show-close />
      </section>

      <span class="section-title">{{ $t("rentacar_driverInformations") }}</span>
      <div id="ui-form-container">
         <div class="form-container" id="validation-error-auto-scroller">
            <div class="form-wrapper">
               <UIInput
                  :label="$t('common_name')"
                  v-model="model.name"
                  v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
            </div>
            <div class="form-wrapper">
               <UIInput
                  :label="$t('common_surname')"
                  v-model="model.surname"
                  v-input="{ type: 'alphabetic', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
            </div>
            <div class="form-wrapper">
               <UIDatePicker
                  :max="MaxDate"
                  :min="MinDate"
                  :label="$t('common_birthday')"
                  v-model="model.birthdate"
                  v-input="{ type: 'date', required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
            </div>

            <div class="form-wrapper">
               <PhoneComponent :contactInfo.sync="model.phone"/>
            </div>


            <!-- <div class="form-wrapper">
               <UIInput label="Phone" v-model="driver.phone" />
            </div> -->
            <div class="form-wrapper">
               <UIInput
                  :label="$t('common_email')"
                  v-model="model.email"
                  v-input="{ type: 'email', len: [3, 320], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
            </div>
            <div class="form-wrapper">
               <UIInput
                  :label="$t('common_flightNumberOptional')"
                  v-model="model.flightNumber"
                  v-input="{ type: 'alphanumeric', len: [2, 30], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
            </div>
            <div class="form-wrapper">
               <div class="g-input-container gts-input-adress">
                  <label>{{ $t("common_address") }}</label>
                  <textarea v-model="model.address"></textarea>

                  <div class="g-form-validation-adress" v-if="typeof model.address == 'object' && validated == false && address == true">
                     {{ $t("common_cannotBeEmpty") }}
                  </div>
               </div>
            </div>
         </div>

         <!-- {{ showAddCustomer }} -->

         <div class="driver-informations-helper-wrapper d-none d-md-block">
            <button class="pax-row-add-customer" :class="showAddCustomer ? '' : 'invisible'" @click="handlerAddNewCustomer">
               <i class="gts-icon icon-user-add"></i>
            </button>

            <button class="pax-row-add-customer" @click="triggerCustomerListModal">
               <i class="gts-icon icon-user-search"></i>
            </button>
         </div>
      </div>
   </div>
</template>

<script>
import Popup from "@/gts-popup.js";
import { customerService } from "@/service/index.js";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UIDatePicker from "@/components/unitary/form2/UIDatePicker2.vue";
// import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import CustomerManagement from "@/components/structural/flight/common/CustomerManagement.vue";
import moment from "moment";
import PhoneComponent from '@/components/unitary/PhoneComponent.vue';

export default {
   name: "DriverInformations",
   props: {
      model: Object,
      minDriverAge: Number,
      validated: Boolean,
      address: Boolean,
   },
   data() {
      return {
         addressIsEmpty: false,
         showAddCustomer: true,
         countriesObj: [],
         showFihrist: false,
         customerEdit: {
            status: false,
            watcher: null,
         },
      };
   },
   components: {
      UIInput,
      UIDatePicker,
      // UISelectBox,
      PhoneComponent,
      CustomerManagement,
   },
   computed: {
      CountryOptionList() {
         const self = this;
         return self.countriesObj;
      },
      MaxDate() {
         return moment()
            .subtract(this.minDriverAge - 1, "y")
            .format(this.$constants.dateFormat);
      },
      MinDate() {
         return moment().subtract(80, "y").format(this.$constants.dateFormat);
      },
   },
   methods: {
      handlerSetCustomer(customer) {
         // console.log(customer);
         const self = this;

         if (this.customerEdit.watcher != null) {
            this.customerEdit.watcher();
         }

         self.model.name = customer.name;
         self.model.surname = customer.surname;
         self.model.birthdate = customer.birthdate != null ? moment(customer.birthdate).format(this.$constants.dateFormat) : null;
         self.model.phone = customer.phone;
         self.model.email = customer.email;

         self.customerEdit.status = true;

         this.customerEdit.watcher = this.$watch(
            "model",
            () => {
               self.customerEdit.status = false;
            },
            { deep: true }
         );
      },

      handlerAddNewCustomer() {
         const self = this;

         // console.log(this.model.passport);

         const modelRowObj = {
            agencyId: this.$store.state.user.user.agencyId,
            agencyName: this.$store.state.user.user.agencyName,
            status: 1,
            gender: this.model.gender,
            birthdate: this.model.birthdate,
            name: this.model.name?.alphabetic(),
            surname: this.model.surname?.alphabetic(),
            email: null,
            phone: this.model.phone,
            nationality: this.model.nationality,
            nationalIdentity: null,
            passport: this.model.passport
               ? this.model.passport
               : {
                    serial: null,
                    number: null,
                    country: null,
                    expiry: null,
                 },
            milesPan: this.model.milesPan,
            hesCode: this.model.hesCode,

            id: 0,
            dbState: 1,
            insertedUserId: null,
            insertedUserEmail: null,
            insertedDatetime: null,
            updatedUserId: null,
            updatedUserEmail: null,
            updatedDatetime: null,
         };

         Popup.confirm(self.$i18n.t("common_information"), self.$i18n.t("customerManagement_popupNewCustomerWillBeAdded"));
         self.$nextTick(() => {
            document.body.querySelector("#popup-ok").addEventListener("click", function () {
               customerService.setCustomer(modelRowObj).then((response) => {
                  if (response.result.success) {
                     Popup.success(self.$i18n.t("common_success"), self.$i18n.t("customerManagement_popupCustomerAddedSubtitle"));
                  }
               });
            });
         });
      },
      triggerCustomerListModal() {
         this.showFihrist = true;
      },
   },
   watch: {
      model: {
         handler: function (val) {
            const { name, surname, birthdate, phone } = val;
            const { city, number } = phone;
            this.showAddCustomer = [name, surname, birthdate, city, number].every(Boolean);

            this.$emit("driver", val);
         },
         deep: true,
         immediate: true,
      },
      "$store.state.app.countryList": {
         handler: function (countries) {
            const self = this;

            countries.forEach((country) => {
               const rowObj = {
                  value: country.phoneCode,
                  name: `${country.phoneCode} `,
                  icon: country.code,
                  fullObj: country,
               };
               self.countriesObj.push(rowObj);
            });
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#rent-a-car-driver-informations-container {
   position: relative;
   .customer-management-wrapper {
      position: absolute;
      z-index: 99;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 1rem;
      right: 0;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

      @media (min-width: 992px) {
         width: 800px;
      }
   }
   #ui-form-container {
      display: flex;
      .form-container {
         // background: red;
         display: flex;
         flex-wrap: wrap;
         width: 90%;
         @media (max-width: 576px) {
            width: 100%;

            // border: 1px solid red !important;
         }

         .form-wrapper {
            width: 50%;
            margin-bottom: 1.25rem;
            padding: 0 1rem;

            @media (max-width: 576px) {
               width: 100%;
               padding: 0 !important;
               // border: 1px solid red !important;
            }

            ::v-deep {
               .g-input-container {
                  .gts-input-wrapper {
                     input {
                        background: #f7f9fc;
                        border: 2px solid #cacaca;
                     }
                  }
               }
            }

            &:last-child {
               width: 100%;

               textarea {
                  width: 100%;
                  height: 106px;
                  background: #f7f9fc;
                  border: 2px solid #cacaca;
                  border-radius: 0.5rem;
                  padding: 0.5rem 1rem;
               }
            }

            // padding: 0.5rem;
            // margin: 0.5rem;
            // background: green;

            &.phone {
               display: flex;
               ::v-deep {
                  .g-select-box-container {
                     width: 142px;
                     margin-right: 1rem;
                  }
                  .g-input-container {
                     &:last-child {
                        margin-left: 1rem;
                     }
                  }
               }
            }

            .gts-input-adress {
               // background: red;
               position: relative;

               .g-form-validation-adress {
                  display: block !important;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  background: #ff4c4c;
                  color: #fff;
                  padding: 0.125rem 0.5rem;
                  font-size: var(--small);
                  opacity: 1;
                  border-radius: 0.5rem;
                  border-top-left-radius: 0;
                  border-top-right-radius: 0;
                  z-index: 50;
                  min-width: 200px;
               }
            }
         }
         // display: grid;
         // grid-template-columns: 1fr 1fr;
         // grid-column-gap: 1rem;
      }
      .driver-informations-helper-wrapper {
         @media (min-width: 576px) {
            margin-top: 0;
         }
         @media (min-width: 1400px) {
            margin-top: 2.4rem;
         }
         button {
            background-color: transparent;
         }
      }
   }
}
</style>
