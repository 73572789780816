<template>
   <div class="passenger-card-container">
      <div class="passenger-card-pax-wrapper">
         <span class="pax-index">
            {{ cardIndex + 1 }}
         </span>
         <div class="pax-details-wrapper">
            <span class="pax-name">
               {{ passenger.passenger.name + ", " + passenger.passenger.surname }}
            </span>
            <span>{{ $options.filters.EFltPaxType(passenger?.passenger?.type) }}</span>

            <ul class="passenger-information-box-ancillary">
               <li v-for="(ancillary, ancillaryIndex) in passenger.ancillaries" :key="ancillaryIndex">
                  <UITooltip
                     :tooltip-title="$t('enum_' + $options.filters.EFltAncillaryType(ancillary?.type))"
                     :tooltip-icon="'gts-icon icon-ancillary-' + $options.filters.EFltAncillaryType(ancillary?.type)"
                     tooltip-position="bottom"
                     v-if="ancillary?.status == 1">
                  </UITooltip>
               </li>
            </ul>
         </div>
      </div>

      <div class="passenger-card-informations-wrapper">
         <div class="passenger-card-informations-rows-wrapper">
            <!-- //Reissues Loop -->
            <template v-if="showReissues">
               <template v-for="(reissue, reissueIndex) in passenger.reissues">
                  <ul :key="reissueIndex">
                     <li>
                        <span class="pnr-badge gts-badge text-center text-black" :class="'EFltPnrStatus-' + reissue.status">{{
                           $options.filters.EFltPnrStatus(reissue.status)
                        }}</span>
                     </li>
                     <li>
                        <span class="txt-small txt-mono">{{ reissue.insertedDatetime | fullDateFormat }}-</span>
                     </li>
                     <li>
                        <span class="txt-small txt-mono">{{ reissue.eticketNumber }}</span>
                     </li>
                     <li>
                        <span class="text-center">{{ reissue.baggage }} </span>
                     </li>
                     <li>
                        <span class="txt-right txt-black">
                           {{ reissue.fare | price(reissue.currency) }}
                        </span>
                     </li>
                     <li>
                        <span class="txt-right txt-black">
                           {{ (reissue.taxI + reissue.taxE) | price(reissue.currency) }}
                        </span>
                     </li>
                     <li>
                        <span class="txt-right txt-black">
                           {{ reissue.markups | price(reissue.currency) }}
                        </span>
                     </li>
                     <li>
                        <span class="txt-right txt-black">
                           {{ reissue.grandTotal | price(reissue.currency) }}
                        </span>
                     </li>
                  </ul>
               </template>
            </template>
            <!-- //Etickets Loop -->
            <template v-for="(eticket, eticketIndex) in passenger.etickets">
               <ul
                  :key="eticketIndex + Math.floor(Math.random() * 99)"
                  :class="$options.filters.EOdrType(packages.type) == $options.filters.EOdrType(5) ? 'eticket-loops' : ''">
                  <li>
                     <span class="pax-mobile-thTitle">{{ $t("orderDetailPage_status") }}</span>
                     <span class="pnr-badge gts-badge text-center text-black" :class="'EFltPnrStatus-' + eticket.status">{{
                        eticket.status | EFltEticketStatus
                     }}</span>
                  </li>
                  <li v-if="$options.filters.EOdrType(packages.type) == $options.filters.EOdrType(5)">
                     <span class="txt-black txt-small">{{ $t("passengerCard_dateofBirth") }}</span>
                     <span>{{ passenger.passenger.birthdate | fullDateFormat }}</span>
                  </li>
                  <li>
                     <span class="pax-mobile-thTitle">{{ $t("orderDetailPage_date") }}</span>
                     <span class="txt-small txt-mono">{{ eticket.insertedDatetime | fullDateFormat }}</span>
                  </li>
                  <li v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
                     <span class="pax-mobile-thTitle">{{ $t("orderDetailPage_eticket") }}</span>
                     <span class="txt-center txt-mono" v-for="(i, index) in handlerStringToArray(eticket.etickets)" :key="index + '-' + eticketIndex">
                        {{ i }}
                     </span>
                     <span class="txt-small txt-mono">{{ eticket.eticketNumber }}</span>
                  </li>
                  <li>
                     <span class="pax-mobile-thTitle">{{ $t("orderDetailPage_baggage") }}</span>
                     <span class="txt-centers txt-mono" v-for="(i, index) in handlerStringToArray(eticket.baggage)" :key="index"> {{ i }} </span>
                  </li>
                  <li v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
                     <span class="pax-mobile-thTitle">{{ $t("orderDetailPage_fare") }}</span>
                     <span class="txt-right txt-black">{{ eticket.fare | price(currency) }}</span>
                  </li>
                  <li v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
                     <span class="pax-mobile-thTitle">{{ $t("orderDetailPage_tax") }}</span>
                     <span class="txt-right txt-black">{{ (eticket.taxI + eticket.taxE) | price(currency) }}</span>
                  </li>
                  <li v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
                     <span class="pax-mobile-thTitle">{{ $t("orderDetailPage_serviceFee") }}</span>
                     <span class="txt-right txt-black"> {{ eticket.markups | price(currency) }}</span>
                  </li>
                  <li v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)">
                     <span class="pax-mobile-thTitle">{{ $t("orderDetailPage_grandTotal") }}</span>
                     <span class="txt-right txt-black">{{ eticket.grandTotal | price(currency) }}</span>
                  </li>
               </ul>
            </template>

            <!-- //Ancillaries Loop -->

            <template v-if="showAncillaries">
               <template v-for="(ancillary, ancillaryIndex) in passenger.ancillaries">
                  <ul :key="ancillaryIndex" class="ancillaries-loop">
                     <li>
                        <span
                           class="pnr-badge gts-badge text-center text-bold"
                           :class="ancillary.status == 1 ? 'EFltPnrStatus-3' : 'EFltPnrStatus-5'"
                           >{{ $options.filters.EFltSsrType(ancillary?.type) }}</span
                        >
                     </li>
                     <li>
                        <span class="txt-small txt-mono">{{ ancillary.insertedDatetime | fullDateFormat }}</span>
                     </li>
                     <li>
                        <span class="txt-small txt-mono" v-for="(detail, detailIndex) in ancillary.details" :key="detailIndex">
                           {{ detail.emdNumber }}
                        </span>
                     </li>

                     <!-- FOOD -->
                     <li>
                        <span class="txt-mono" v-for="(detail, key, detailIndex) in ancillary.details" :key="detailIndex">
                           <template v-if="ancillary?.type == 3">
                              <div class="special-view">
                                 <!-- {{ $t($options.filters.EFltSsrMealType(foodtypeReverse[detail.info])) }} -->
                                 <UITooltip
                                    :tooltip-title="$t($options.filters.EFltSsrMealType(foodtypeReverse[detail.info]))"
                                    tooltip-icon=""
                                    tooltip-position="bottom"
                                    no-padding>
                                    <span v-html="handlerSpecialViewDisplay(detail.info)"></span>
                                    <span class="route-info">{{ detail.route }}</span>
                                 </UITooltip>
                              </div>
                           </template>

                           <template v-else-if="ancillary?.type == 6 || ancillary?.type == 7 || ancillary?.type == 8">
                              <div class="special-view">
                                 <span class="text-censter check-in-done"> </span>
                                 <span class="route-info">{{ detail.route }}</span>
                              </div>
                           </template>
                           <template v-else-if="ancillary?.type == 4">
                              <div class="special-view">
                                 <UITooltip
                                    :tooltip-title="$t($options.filters.EFltSsrWheelchairType(wheelChairReverse[detail.info]))"
                                    tooltip-icon=""
                                    tooltip-position="bottom"
                                    no-padding>
                                    <span v-html="handlerSpecialViewDisplay(detail.info)"></span>
                                    <span class="route-info">{{ detail.route }}</span>
                                 </UITooltip>
                              </div>
                           </template>
                           <template v-else>
                              <div class="special-view">
                                 <span v-html="handlerSpecialViewDisplay(detail.info)"></span>
                                 <span class="route-info">{{ detail.route }}</span>
                                 <!-- <UITooltip :tooltip-title="$t($options.filters.EFltSsrMealType(foodtypeReverse[detail.info]))" tooltip-icon="" tooltip-position="bottom" no-padding>
                                   
                                 </UITooltip> -->
                              </div>
                           </template>
                        </span>
                     </li>

                     <li>
                        <span class="txt-right txt-black">
                           {{ ancillary.fare | price(currency) }}
                        </span>
                     </li>
                     <li>
                        <span class="txt-right txt-black">
                           {{ ancillary.tax | price(currency) }}
                        </span>
                     </li>
                     <li>
                        <span class="txt-right txt-black">
                           {{ ancillary.markups | price(currency) }}
                        </span>
                     </li>
                     <li>
                        <span class="txt-right txt-black">
                           {{ ancillary.grandTotal | price(currency) }}
                        </span>
                     </li>
                  </ul>
               </template>
            </template>

            <!-- //Passenger Details -->
            <template v-if="showDetails">
               <ul>
                  <li>
                     <span class="txt-black txt-small">{{ $t("passengerCard_dateofBirth") }}</span>
                     <span>{{ passenger.passenger.birthdate | fullDateFormat }}</span>
                  </li>
                  <li>
                     <span class="txt-black txt-small">
                        {{ $t("passengerCard_nationality") }}
                     </span>
                     <span>
                        {{ passenger.passenger.nationality }}
                     </span>
                  </li>
                  <li>
                     <span class="txt-black txt-small">
                        {{ $t("passengerCard_milNo") }}
                     </span>
                     <span>
                        {{ passenger.passenger.passport.country }}
                     </span>
                  </li>
                  <!-- <li>
                     <span class="txt-black txt-small">
                        {{ $t("passengerCard_serialNumber") }}
                     </span>
                     <span>
                        {{ passenger.passenger.passport.serial }}
                     </span>
                  </li> -->
                  <li>
                     <span class="txt-black txt-small">
                        {{ $t("passengerCard_passportNumber") }}
                     </span>
                     <span>
                        {{ passenger.passenger.passport.number }}
                     </span>
                  </li>
                  <li>
                     <span class="txt-black txt-small">
                        {{ $t("passengerCard_expiryDate") }}
                     </span>
                     <span>
                        {{ passenger.passenger.passport.expiry | fullDateFormat }}
                     </span>
                  </li>
               </ul>
            </template>
         </div>

         <!-- Passenger Detail Toggler -->
         <div
            v-if="$options.filters.EOdrType(packages.type) != $options.filters.EOdrType(5)"
            class="passenger-card-detail-toggler"
            @click="showDetails = !showDetails"
            :class="showDetails ? 'details-active' : ''">
            <i class="gts-icon icon-arrow-down icon-sm"></i>
         </div>
      </div>
   </div>
</template>

<script>
// import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import { EFltSsrMealTypeReverse, EFltSsrWheelchairTypeReverse } from "@/enums/flightEnums.js";
import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "PassengerCard",
   props: {
      packages: Object,
      passenger: Object,
      cardIndex: Number,
      currency: String,
   },
   components: {
      UITooltip,
      // UISelectBox,
   },
   data() {
      return {
         foodtypeReverse: EFltSsrMealTypeReverse,
         wheelChairReverse: EFltSsrWheelchairTypeReverse,
         showReissues: false,
         showAncillaries: true,
         showDetails: false,
         showBaggageOptions: false,
         Options: [
            { value: 0, name: "Select Baggage" },
            { value: 1, name: "20 Kg, 20.00€" },
            { value: 2, name: "25 Kg, 40.00€" },
            { value: 3, name: "30 Kg, 60.00€" },
            { value: 4, name: "40 Kg, 100.00€" },
            { value: 5, name: "50 Kg, 140.00€" },
         ],
         selection: 0,
      };
   },
   methods: {
      handlerSpecialViewDisplay(val) {
         if (val.includes("KG")) {
            return `${val.replace("KG", "")}<i>KG</i> `;
         } else if (val.includes("PRIBAS_SUN_")) {
            return val.replace("PRIBAS_SUN_", "");
         } else {
            return val;
         }
      },
      handlerStringToArray(string) {
         if (string == undefined) {
            return [];
         } else {
            return string.split(",");
         }
      },
   },
   watch: {
      "$store.state.flight.orderDetail.showAncillary": {
         handler: function (isShow) {
            const self = this;
            self.showAncillaries = isShow;
         },
      },
      "$store.state.flight.orderDetail.showReissues": {
         handler: function (isShow) {
            const self = this;
            self.showReissues = isShow;
         },
      },
   },
};
</script>

<style lang="scss">
.gts-badge {
   white-space: nowrap !important;
}
.special-view {
   display: flex !important;
   span {
      width: fit-content !important;
      min-width: 60px;
      overflow: hidden;

      i {
         // font-size: 0.8rem;
      }
      &.route-info {
         margin-left: 0.5rem;
         background-color: #fdeff8;
         white-space: nowrap;
         font-size: 0.7rem;
         font-weight: 600;
         color: #b06796;
         display: inline;
         height: fit-content;
         width: 100%;
         min-width: 72px;
         padding: 0.125rem 0.125rem;
         text-align: center;
         border-radius: 0.25rem;
      }
   }
   // border: 1px solid #eee;
}
</style>

<style lang="scss" scoped>
.passenger-information-mobile-container {
   .passenger-information-row-container {
      > div {
         ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 0.5rem;
            li {
               display: flex;
               flex-direction: column;
               align-items: center;

               span {
                  font-weight: 800;
                  font-size: var(--large);
                  &.passenger-information-thTitle {
                     font-size: var(--normal);
                     opacity: 0.8;
                     font-weight: 400;
                  }
               }
            }
         }
      }

      .ancillaries {
         ul {
            border-top: 1px solid #ccc;
            padding: 1rem 0;
         }
      }
   }
   .pax-informations {
      border-bottom: 1px solid #ccc;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
   }
}
</style>
<style lang="scss" scoped>
.passenger-card-container {
   background-color: #fafbfc;
   // padding: 1rem;
   border-radius: 16px;
   border: 1.5px solid #cacaca;
   display: flex;
   margin: 0.5rem 0;
   // overflow: hidden;

   @media (max-width: 768px) {
      // display: none;
      flex-direction: column;
      width: 100%;
   }

   .passenger-card-pax-wrapper {
      display: flex;
      flex-direction: row;
      background-color: #f2f2f2;
      border-right: 1px solid #cacaca;
      padding: 1rem;
      width: 260px;
      border-radius: 16px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      @media (max-width: 768px) {
         // display: none;
         flex-direction: column;
         width: 100%;
      }

      .pax-index {
         font-size: 2.125rem;
         color: var(--txt-black);
         font-family: "Poppins";
         border-right: 1px solid #d3d3d3;
         padding: 0 0.5rem 0 0rem;
         min-width: 45px;
         text-align: center;
         @media (max-width: 768px) {
            display: none;
         }
      }
      .pax-details-wrapper {
         display: flex;
         flex-direction: column;
         padding-left: 1rem;

         span.pax-name {
            font-size: var(--large);
            color: var(--txt-black);
            font-weight: 500;
         }

         @keyframes attention {
            0% {
               opacity: 0;
            }
            80% {
               opacity: 1;
            }
            100% {
               opacity: 1;
            }
         }

         .passenger-information-box-ancillary {
            opacity: 0;
            display: flex;
            width: fit-content;
            animation-name: attention;
            animation-fill-mode: forwards;
            animation-iteration-count: 5;
            animation-timing-function: ease-out;
            animation-duration: 0.75s;
            flex-wrap: wrap;
            // background: red;

            li {
               margin: 0 0;

               &:first-child {
                  margin-left: 0;
               }
               &:last-child {
                  margin-right: 0;
               }

               ::v-deep {
                  i {
                     $iconSize: 38px !important;
                     width: $iconSize;
                     height: $iconSize;
                  }
               }
            }
         }
      }
   }

   .passenger-card-informations-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1rem;

      .passenger-card-informations-rows-wrapper {
         width: 100%;
      }

      ul {
         display: grid;
         grid-template-columns: 0.75fr 0.75fr 1fr 1fr repeat(4, 0.75fr);
         grid-column-gap: 1rem;
         width: 100%;
         border-bottom: 1px dashed #ccc;
         height: fit-content;
         padding-bottom: 0.5rem;
         margin-bottom: 0.5rem;
         // border: 1px solid;
         &.eticket-loops {
            grid-template-columns: repeat(4, 1fr);
         }
         @media (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
         }

         &:last-child,
         &:first-child {
            border-bottom: none !important;
         }

         li {
            width: 100%;
            // border: 1px solid;
            span {
               display: inline-block;
               width: 100%;

               @media (max-width: 768px) {
                  text-align: center !important;
                  font-size: var(--large);
               }

               &.pax-mobile-thTitle {
                  display: none;
                  @media (max-width: 768px) {
                     display: block;
                     font-weight: 500;
                     font-size: var(--normal);
                  }
               }
               &.check-in-done {
                  background-repeat: no-repeat;
                  background-position-x: left;
                  background-size: contain;
                  width: 100%;
                  height: 26px;
                  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCIgdmlld0JveD0iMCAwIDMwIDMwIj4KICA8ZyBpZD0iR3JvdXBfMSIgZGF0YS1uYW1lPSJHcm91cCAxIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEyIC0xNzcpIj4KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMTM3NyIgZGF0YS1uYW1lPSJFbGxpcHNlIDEzNzciIGN4PSIxNSIgY3k9IjE1IiByPSIxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTQyIDE3Nykgcm90YXRlKDkwKSIgZmlsbD0iI2U4ZmNmMiIvPgogICAgPGNpcmNsZSBpZD0iRWxsaXBzZV8xMzc3LTIiIGRhdGEtbmFtZT0iRWxsaXBzZSAxMzc3IiBjeD0iMTMiIGN5PSIxMyIgcj0iMTMiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE0MCAxNzkpIHJvdGF0ZSg5MCkiIGZpbGw9IiNiY2Y1ZDkiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KICAgIDxsaW5lIGlkPSJMaW5lXzExMzAiIGRhdGEtbmFtZT0iTGluZSAxMTMwIiB4MT0iMy44OTYiIHkxPSIyLjUzNyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTIyLjAxMSAxOTMuNjQ2KSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMTY5YzU5IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPgogICAgPGxpbmUgaWQ9IkxpbmVfMTI2OCIgZGF0YS1uYW1lPSJMaW5lIDEyNjgiIHgxPSI1LjY0OCIgeTI9IjguMzY1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjUuOTA3IDE4Ny44MTcpIiBmaWxsPSJub25lIiBzdHJva2U9IiMxNjljNTkiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K");
               }
            }
         }
      }
      .passenger-card-detail-toggler {
         background-color: #eaeaea;
         padding: 0.5rem 0.5rem;
         border-radius: 8px;
         display: flex;
         align-items: center;
         height: fit-content;
         margin-left: 1rem;
         cursor: pointer;

         i {
            transform: rotate(0deg);
            transition-property: transform;
            transition-timing-function: ease-in-out;
            transition-duration: 0.25s;
         }

         &.details-active {
            i {
               transform: rotate(-180deg);
            }
         }

         &:hover {
            background-color: #d8d8d8;
         }
      }
   }
}
</style>
