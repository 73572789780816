import Vue from "vue";
import i18n from "@/fmt/i18n";

/*
enumName : name of the enumeration
enumObj  : enumeration object (import)
noGroupCode : true ? "" : enumName + '_'
*/
Vue.Enum = (enumName, enumObj, noGroupCode) => {
   Vue.filter(enumName, createEnumFilter(enumName, enumObj, noGroupCode));
};

function createEnumFilter(a, b, c) {
   return (value) => {
      return i18n.t(`${c ? "" : a + "_"}${b[value] != null || b[value] != undefined ? b[value] : "UNKNOWN"}`);
   };
}

import * as flightEnums from "./flightEnums";
import * as hotelEnums from "./hotelEnums";
import * as dashboardEnums from "./dashboardEnums";
import * as orderEnums from "./orderEnums";
import * as paymentEnums from "./paymentEnums";
import * as rentacarEnums from "./rentacarEnums";
import * as taskEnums from "./taskEnums";
import * as transferEnums from "./transferEnums";
import * as userEnums from "./userEnums";
import * as commonEnums from "./commonEnums";
import * as schmetterlingEnums from "./schmetterlingEnums";

/*
Vue.Enum generators
   - import enums
   - use with Vue.Enum function
*/

// Flight Enums
Vue.Enum("EFltCabinType", flightEnums.EFltCabinType);
Vue.Enum("EFltEticketStatus", flightEnums.EFltEticketStatus);
Vue.Enum("EFltPnrStatus", flightEnums.EFltPnrStatus);
Vue.Enum("EFltPaxType", flightEnums.EFltPaxType);
Vue.Enum("EFltSsrType", flightEnums.EFltSsrType);
Vue.Enum("EFltSsrMealType", flightEnums.EFltSsrMealType);
Vue.Enum("EFltSsrMealTypeEnum", flightEnums.EFltSsrMealType, true);
Vue.Enum("EFltSsrMealTypeReverse", flightEnums.EFltSsrMealTypeReverse);
Vue.Enum("EFltSsrWheelchairType", flightEnums.EFltSsrWheelchairType);
Vue.Enum("EFltSsrWheelchairTypeEnum", flightEnums.EFltSsrWheelchairType, true);
Vue.Enum("EFltAncillaryType", flightEnums.EFltAncillaryType, true);
Vue.Enum("EFltSeatTypeEnum", flightEnums.EFltSeatTypeEnum);
Vue.Enum("EFltSeatLayout", flightEnums.EFltSeatLayout, true);

// Dashboard Enums
Vue.Enum("widgets", dashboardEnums.widgets, true);
Vue.Enum("widgetsResponseModelMap", dashboardEnums.widgetsResponseModelMap);
Vue.Enum("EDashMainWidgetType", dashboardEnums.EDashMainWidgetType);
Vue.Enum("EDashMainWidget2", dashboardEnums.EDashMainWidget2);
Vue.Enum("widgets2", dashboardEnums.widgets2, true);
Vue.Enum("widgetsResponseModelMap2", dashboardEnums.widgetsResponseModelMap2);

// Hotel Enums
Vue.Enum("EHtlVoucherStatus", hotelEnums.EHtlVoucherStatus);
Vue.Enum("EHtlRoomPriceStatus", hotelEnums.EHtlRoomPriceStatus);
Vue.Enum("EHtlGuestType", hotelEnums.EHtlGuestType);
Vue.Enum("EReportHotelDateType", hotelEnums.EReportHotelDateType);
Vue.Enum("EHtlRefundType", hotelEnums.EHtlRefundType);
Vue.Enum("EHtlPriceRestrictionType", hotelEnums.EHtlPriceRestrictionType);
Vue.Enum("EHtlPolicyType", hotelEnums.EHtlPolicyType);
Vue.Enum("EHtlAmountType", hotelEnums.EHtlAmountType);
Vue.Enum("EOdrSearchHotelDateType", hotelEnums.EOdrSearchHotelDateType);

// Order Enums
Vue.Enum("EOdrOrderStatus", orderEnums.EOdrOrderStatus);
Vue.Enum("EOdrEntityType", orderEnums.EOdrEntityType);
Vue.Enum("EOdrTrxType", orderEnums.EOdrTrxType);
Vue.Enum("EOdrType", orderEnums.EOdrType);

// Payment Enums
Vue.Enum("EPayPaymentType", paymentEnums.EPayPaymentType);

// Rentacar Enums
Vue.Enum("ERacAncillaryType", rentacarEnums.ERacAncillaryType);
Vue.Enum("ERacAncillaryEnums", rentacarEnums.ERacAncillaryEnums);
Vue.Enum("ERacAncillaryTypeIcon", rentacarEnums.ERacAncillaryTypeIcon, true);
Vue.Enum("ERacPassengerCapacity", rentacarEnums.ERacPassengerCapacity);
Vue.Enum("ERacGearType", rentacarEnums.ERacGearType);
Vue.Enum("ERacFuelType", rentacarEnums.ERacFuelType);
Vue.Enum("ERacBodyType", rentacarEnums.ERacBodyType);
Vue.Enum("ERacFacilityType", rentacarEnums.ERacFacilityType);
Vue.Enum("ERacRnrStatus", rentacarEnums.ERacRnrStatus);
Vue.Enum("EOdrSearchRentACarDateType", rentacarEnums.EOdrSearchRentACarDateType);
Vue.Enum("EReportRentACarDateType", rentacarEnums.EReportRentACarDateType);
Vue.Enum("EReportRentACarReportType", rentacarEnums.EReportRentACarReportType);

// Task Enums
Vue.Enum("ETaskStatus", taskEnums.ETaskStatus);
Vue.Enum("ETaskSubStatus", taskEnums.ETaskSubStatus);
Vue.Enum("ETaskType", taskEnums.ETaskType);
Vue.Enum("ETaskSubType", taskEnums.ETaskSubType);
Vue.Enum("ETaskClosingType", taskEnums.ETaskClosingType);

// Transfer Enums
Vue.Enum("ETrfTnrStatus", transferEnums.ETrfTnrStatus);
Vue.Enum("ETrfLegStatus", transferEnums.ETrfLegStatus);
Vue.Enum("ETrfOperationStatus", transferEnums.ETrfOperationStatus);
Vue.Enum("ETrfCommissionType", transferEnums.ETrfCommissionType);
Vue.Enum("ETrfCommissionAmountType", transferEnums.ETrfCommissionAmountType);
Vue.Enum("ETrfVehicleCategory", transferEnums.ETrfVehicleCategory);
Vue.Enum("ETrfFacilityType", transferEnums.ETrfFacilityType);
Vue.Enum("ETrfTransferType", transferEnums.ETrfTransferType);
Vue.Enum("ETrfProductType", transferEnums.ETrfProductType);
Vue.Enum("ETrfTravelerType", transferEnums.ETrfTravelerType);
Vue.Enum("ETrfSaleStatus", transferEnums.ETrfSaleStatus);
Vue.Enum("ETrfAncillaryType", transferEnums.ETrfAncillaryType);
Vue.Enum("ETrfRouteOriginType", transferEnums.ETrfRouteOriginType);
Vue.Enum("EReportTransferDateType", transferEnums.EReportTransferDateType);

// User Enums
Vue.Enum("EUserType", userEnums.EUserType);

// Common Enums
Vue.Enum("EComGender", commonEnums.EComGender);
Vue.Enum("EComLanguage", commonEnums.EComLanguage);
Vue.Enum("EComResult", commonEnums.EComResult);
Vue.Enum("EComStatus", commonEnums.EComStatus);
Vue.Enum("EComApplication", commonEnums.EComApplication);

// Schmetterling Enums

Vue.Enum("ESchmetterlingOrderStatus", schmetterlingEnums.ESchmetterlingOrderStatus);
