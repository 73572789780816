<template>
   <section class="rent-a-car-order-detail-section driver-information-wrapper">
      <div class="driver-information-inner">
         <div class="rent-a-car-order-detail-section-title">
            <h4>{{ $t("orderDetail_lblDriverInfoTitle") }}</h4>
         </div>
         <div class="rent-a-car-order-detail-section-content">
            <div class="driver-information-summary-wrapper rent-a-car-order-detail-inner-section">
               <div class="driver-information-summary-inner">
                  <div class="driver-information-table-wrapper">
                     <div class="driver-information-table-inner">
                        <div class="driver-information-table-header">
                           <div class="table-header-list">
                              <div class="driver-info driver-name">
                                 <span class="driver-info-title">{{ $t("orderDetail_lblNameSurname") }}</span>
                                 <span class="driver-info-text">{{ driver.name }} {{ driver.surname }}</span>
                              </div>
                              <div class="driver-info driver-birthdate">
                                 <span class="driver-info-title">{{ $t("orderDetail_lblBirthdate") }}</span>
                                 <span class="driver-info-text">{{ driver.birthdate | fullDateFormat }}</span>
                              </div>
                              <div class="driver-info driver-flight-number">
                                 <span class="driver-info-title">{{ $t("orderDetail_lblFlightNumber") }}</span>
                                 <span class="driver-info-text">{{ driver.flightNumber }}</span>
                              </div>
                              <div class="driver-info driver-email">
                                 <span class="driver-info-title">{{ $t("orderDetail_lblEmail") }}</span>
                                 <span class="driver-info-text">{{ driver.email }}</span>
                              </div>
                              <div class="driver-info driver-phone">
                                 <span class="driver-info-title">{{ $t("orderDetail_lblPhoneNumber") }}</span>
                                 <span class="driver-info-text">{{ driver.phone | phone }}</span>
                              </div>
                              <div class="driver-info driver-adress">
                                 <span class="driver-info-title"></span>
                                 <span class="driver-info-text"></span>
                              </div>
                              <div class="driver-info driver-adress">
                                 <span class="driver-info-title">{{ $t("orderDetail_lblAdress") }}</span>
                                 <span class="driver-info-text">{{ driver.address }}</span>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name: "DriverInformations",
   props: {
      driver: Object,
   },
   computed: {},
   data() {
      return {
         driverList: this.driver,
      };
   },
   methods: {},
   watch: {
      driver: {
         handler: function (val) {
            this.List = val;
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.driver-information-wrapper {
   .driver-information-inner {
      .rent-a-car-order-detail-section-title {
         margin-bottom: 24px;
         & > h4 {
            font-weight: 700;
         }
      }
      .rent-a-car-order-detail-section-content {
         border: 1px solid #cacaca;
         border-radius: 16px;
         opacity: 1;
         .driver-information-summary-wrapper {
            .driver-information-summary-inner {
               .driver-information-table-wrapper {
                  .driver-information-table-inner {
                     display: block;
                     border-radius: 10px;
                     overflow: hidden;
                     .driver-information-table-header {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        @media (min-width: 992px) {
                           padding: 16px 42px;
                        }

                        span {
                           font-size: 16px;
                           color: #363940;
                           // font-weight: bold;
                        }
                        .table-header-fixed-item {
                           width: 15%;
                           max-width: 15%;
                           margin-right: 24px;
                        }
                        .table-header-list {
                           display: grid;
                           grid-template-columns: repeat(2, 1fr) 0.75fr;
                           width: 100%;

                           @media (max-width: 768px) {
                              grid-template-columns: repeat(2, 1fr)
                           }

                           .driver-info {
                              display: flex;
                              flex-direction: column;
                              margin: 16px;
                              width: 100%;
                              .driver-info-text {
                                 font-weight: bold;
                              }
                           }

                           @media (min-width: 992px) {
                              padding: 0 32px;
                              grid-template-columns: repeat(3, 1fr);
                           }
                        }
                     }
                     .driver-information-table-content {
                        .driver-information-room-item {
                           display: flex;
                           justify-content: flex-start;
                           align-items: stretch;

                           background-color: white;
                           border-bottom: 1px solid #c4c4c4;

                           @media (min-width: 992px) {
                              padding: 16px 42px;
                           }

                           &:last-child {
                              border-bottom: none;
                           }
                           .table-content-fixed-item {
                              width: 15%;
                              max-width: 15%;
                              margin-right: 24px;
                              border-right: 1px solid #bfbfbf;

                              & > span {
                                 display: inline-flex;
                                 justify-content: flex-start;
                                 align-items: center;
                                 width: 100%;
                                 height: 100%;
                                 font-weight: 700;
                              }
                           }
                           .table-content-list {
                              width: 100%;

                              .driver-information-list {
                                 .driver-information-list-item {
                                    display: grid;
                                    grid-template-columns: repeat(2, 1fr) 0.75fr;

                                    align-items: center;
                                    border-radius: 20px;
                                    margin-bottom: 8px;
                                    padding: 0.5rem 0;
                                    border: 1px solid #dedede;

                                    @media (min-width: 992px) {
                                       grid-template-columns: repeat(4, 1fr);
                                       padding: 10px 32px;
                                    }

                                    .reissue-inputs {
                                       width: 67%;
                                    }

                                    span {
                                       color: #363940;
                                       font-weight: bold;
                                    }

                                    &:last-child {
                                       margin-bottom: 0;
                                    }

                                    &:nth-child(odd) {
                                       background-color: #f5f5f5;
                                       border-color: #f5f5f5;
                                    }
                                    &:nth-child(even) {
                                       background-color: white;
                                       border-color: #e2e2e2;
                                    }

                                    & > span:last-child {
                                       display: inline-flex;
                                       justify-content: flex-start;
                                    }
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
