import moment from "moment";

export default {
   DashboardCookie(widgets) {
      var rowObj;
      if (widgets) {
         rowObj = {
            mainWidgets: widgets.main,
            sideWidgets: widgets.side,
         };
      } else {
         rowObj = {
            mainWidgets: [1, 2, 3],
            sideWidgets: ["announcement", "campaign", "balance"],
         };
      }

      document.cookie = `widgets=${JSON.stringify(rowObj)}`;

      return readCookie("widgets");
   },

   modelGenerator(obj) {
      return {
         ...obj,
         extras: showExtras(obj.type),
         multitable: MultiTable(obj.type),
         content: Generator(obj.type, obj.content),
      };
   },
   DynamicFeed(model) {
      return TableGenerator(model);
   },
   reservationsHeader: {
      100: {
         1: "",
         2: "dashboardPage_widgetNameSurname",
         3: "dashboardPage_widgetReservationDetails",
         4: "dashboardPage_widgetTime",
         5: "dashboardPage_widgetReservationNumber",
      },
      101: {
         1: "",
         2: "dashboardPage_widgetNameSurname",
         3: "dashboardPage_widgetProviderRoute",
         4: "dashboardPage_widgetTime",
         5: "dashboardPage_widgetPnr",
      },
      102: {
         1: "",
         2: "dashboardPage_widgetNameSurname",
         3: "dashboardPage_widgetHotelName",
         4: "dashboardPage_widgetTime",
         5: "dashboardPage_widgetVoucher",
      },
   },
   upcomingsHeader: {
      200: {
         1: "",
         2: "dashboardPage_widgetNameSurname",
         3: "dashboardPage_widgetBookingDetails",
         4: "dashboardPage_widgetTime",
         5: "dashboardPage_widgetBookingNumber",
      },
      201: {
         1: "",
         2: "dashboardPage_widgetNameSurname",
         3: "dashboardPage_widgetProviderRoute",
         4: "dashboardPage_widgetTime",
         5: "dashboardPage_widgetPnr",
      },
      202: {
         1: "",
         2: "dashboardPage_widgetNameSurname",
         3: "dashboardPage_widgetHotelName",
         4: "dashboardPage_widgetTime",
         5: "dashboardPage_widgetVoucher",
      },
      203: {
         1: "",
         2: "dashboardPage_widgetNameSurname",
         3: "dashboardPage_widgetRoute",
         4: "dashboardPage_widgetTime",
         5: "dashboardPage_widgetTnr",
      },
      204: {
         1: "",
         2: "dashboardPage_widgetNameSurname",
         3: "dashboardPage_widgetRoute",
         4: "dashboardPage_widgetTime",
         5: "dashboardPage_widgetRnr",
      },
   },
};

function readCookie(name) {
   var result = document.cookie.match(new RegExp(name + "=([^;]+)"));
   result && (result = JSON.parse(result[1]));
   return result;
}

function Generator(multitable, content) {
   const isMultiTable = MultiTable(multitable);
   if (isMultiTable) {
      return TableGenerator(content);
   } else {
      return Array.isArray(content) ? TableGenerator(content) : PerformanceGenerator(content);
      // return [TableGenerator(content), []];
   }
}

// if widget has tabbed content
function MultiTable(identifier) {
   if (identifier == 1 || identifier == 2) {
      switch (identifier) {
         case 1:
            return {
               status: true,
               tabs: [
                  { name: "dashboardPage_widgetReservationsAll", widgetItem: 100 },
                  { name: "dashboardPage_widgetReservationsFlight", widgetItem: 101 },
                  { name: "dashboardPage_widgetReservationsHotel", widgetItem: 102 },
               ],
               service: "GetReservations",
               name: "reservations",
            };
         case 2:
            return {
               status: true,
               tabs: [
                  { name: "dashboardPage_widgetUpcomingsAll", widgetItem: 200 },
                  { name: "dashboardPage_widgetUpcomingsFlight", widgetItem: 201 },
                  { name: "dashboardPage_widgetUpcomingsHotel", widgetItem: 202 },
                  { name: "dashboardPage_widgetUpcomingsTransfer", widgetItem: 203 },
                  { name: "dashboardPage_widgetUpcomingsRentACar", widgetItem: 204 },
               ],
               service: "GetUpcomings",
               name: "upcomings",
            };
      }
   } else {
      return false;
   }
}

// Table Widget Generator
function TableGenerator(model) {
   const modelArr = [];
   model.map((item) => {
      modelArr.push({
         entity: item?.entity,
         entityType: item?.entityType,
         currency: item?.currency,
         status: item?.entityStatus ? item?.entityStatus : null,
         paxFullName: item?.paxFullName ? item?.paxFullName : null,
         route: item?.route ? item?.route : null,
         providerType: item?.providerType ? item?.providerType : null,
         dateTime: item?.optionDateTime || item?.upcomingDateTime,
         onlineCheckInUrl: item?.onlineCheckInUrl ? item?.onlineCheckInUrl : null,
         fltWkscTaskId: item?.fltWkscTaskId ? item?.fltWkscTaskId : 0,
         provider: item?.markModel ? item?.markModel : item?.vehicleType ? item?.vehicleType : item.provider,
         code: item?.code ? item?.code : null,
         gross: item?.gross ? item?.gross : null,
         orderUuid: item?.orderUuid,
         highlightDate: highlightDate(item?.optionDateTime || item?.upcomingDateTime),
         reservedEntityCount: item?.reservedEntityCount ? item?.reservedEntityCount : 0,
         reservedPaxCount: item?.reservedPaxCount ? item?.reservedPaxCount : 0,
         issuedEntityCount: item?.issuedEntityCount ? item?.issuedEntityCount : 0,
         issuedPaxCount: item?.issuedPaxCount ? item?.issuedPaxCount : 0,
         issuedRoomCount: item?.issuedRoomCount ? item?.issuedRoomCount : 0,
      });
   });
   return modelArr;
}

// Performance Widget Generator
function PerformanceGenerator(model) {
   return model;
}

// Helper Functions
showExtras;
function showExtras(type) {
   switch (type) {
      case 101:
         return false;
      case 102:
         return false;
      case 104:
         return true;
      default:
         return false;
   }
}

function highlightDate(date) {
   const today = moment()._d;
   const expiryTime = moment(date)._d;
   const calculation = expiryTime - today;
   return calculation < 8.64e7 ? true : false;
}
