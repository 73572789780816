var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"widget-multi-table-container"},[_c('Cards',{attrs:{"status":_vm.properties?.extras,"rows":_vm.properties?.content}}),(_vm.properties?.multitable?.status)?_c('Section',{staticClass:"multiple-table-header-wrapper"},[_c('div',{staticClass:"multi-table-tabs"},[_vm._l((_vm.properties.multitable?.tabs),function(tab,index){return [_c('div',{key:index,staticClass:"multi-table-tab",class:{ 'multi-table-active-tab': index === _vm.mode, 'disabled-tab': _vm.progress },on:{"click":function($event){return _vm.handlerSwitchTab(index, tab.widgetItem)}}},[_vm._v(" "+_vm._s(_vm.$t(tab.name))+" ")])]})],2)]):_vm._e(),_c('Table',{attrs:{"properties":_vm.properties,"rows":_vm.properties?.content,"loading":_vm.progress,"resetPagination":_vm.resetPagination,"activeTab":_vm.activeTab},on:{"expiredFlights":() => {
            this.expiredFlights = !this.expiredFlights;
            _vm.handlerSwitchTab(_vm.mode, _vm.properties.multitable.tabs[_vm.mode].widgetItem, true);
         },"onlyFltWksc":() => {
            this.onlyFltWksc = !this.onlyFltWksc;
            _vm.handlerSwitchTab(_vm.mode, _vm.properties.multitable.tabs[_vm.mode].widgetItem, true);
         },"resetPagination":function($event){_vm.resetPagination = !_vm.resetPagination}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }