import TestDev from "@/pages/Test.vue";
import PackageCompTest from "@/pages/PackageCompTest.vue";
import Dashboard from "@/pages/Dashboard.vue";
import News from "@/pages/News.vue";
import Hotel from "@/pages/hotel/HotelSearch.vue";
import HotelCache from "@/pages/hotel/HotelCacheSearch.vue";
import HotelCheckout from "@/pages/hotel/HotelCheckout.vue";
import Flight from "@/pages/flight/FlightSearch.vue";
import FlightCheckout from "@/pages/flight/FlightCheckout.vue";
import RentACar from "@/pages/rentacar/RentACarSearch.vue";
import RentACarCheckout from "@/pages/rentacar/RentACarCheckout.vue";
import Transfer from "@/pages/transfer/TransferSearch";
import TransferCheckout from "@/pages/transfer/TransferCheckout.vue";
import OrderSearch from "@/pages/order/OrderSearch.vue";
import OrderDetail from "@/pages/order/OrderDetail.vue";
import FlightSalesReport from "@/pages/report/FlightSalesReport.vue";
import HotelSalesReport from "@/pages/report/HotelSalesReport.vue";
import TransferSalesReport from "@/pages/report/TransferSalesReport.vue";
import RentACarSalesReport from "@/pages/report/RentACarSalesReport.vue";
import SchmetterlingSalesReport from "@/pages/report/SchmetterlingSalesReport.vue";
import TaskList from "@/pages/task/TaskList.vue";
import InvoiceList from "@/pages/finance/InvoiceList.vue";
import BalanceUpdate from "@/pages/finance/BalanceUpdate.vue";
import AccTransactionList from "@/pages/finance/AccTransactionList.vue";
import Service from "@/pages/service/Service.vue";
import Notification from "@/pages/NotificationPage.vue";
import Version from "@/pages/Version.vue";
import Loading from "@/pages/Loading.vue";
import StyleGuide from "@/dev/StyleGuide.vue";
import Login from "@/pages/user/Login.vue";
import Register from "@/pages/user/Register.vue";
import TermsAndConditions from "@/pages/user/TermsAndConditions.vue";
import PrivacyPolicy from "@/pages/user/PrivacyPolicy.vue";
import Settings from "@/pages/user/settings/Settings.vue";
import ResetPassword from "@/pages/user/ResetPassword.vue";
import PackageSearch from "@/pages/PackageSearch.vue";

const routerList = [
   {
      path: "/",
      name: "Dashboard",
      component: Dashboard,
      meta: {
         title: "Dashboard",
      },
   },
   {
      path: "/guide",
      name: "StyleGuide",
      component: StyleGuide,
      meta: {
         title: "StyleGuide",
      },
   },
   {
      path: "/testdev",
      name: "TestDev",
      component: TestDev,
      meta: {
         title: "TestDev",
      },
   },
   {
      path: "/package",
      name: "Package",
      component: PackageCompTest,
      meta: {
         title: "PackageCompTest",
      },
   },

   {
      path: "/notifications",
      name: "Notification",
      component: Notification,
      meta: {
         title: "Notification",
         roles: [19],
      },
   },
   {
      path: "/hotel",
      name: "HotelSale",
      component: Hotel,
      meta: {
         title: "HotelSale",
         roles: [2],
         agencyRequired: true,
      },
   },
   {
      path: "/apiclientcontrol",
      name: "ApiClientControl",
      component: HotelCache,
      meta: {
         title: "ApiClientControl",
         roles: [2],
         agencyRequired: true,
      },
   },
   {
      path: "/loading",
      name: "Loading",
      component: Loading,
   },
   {
      path: "/hotel/checkout/:uuid/:basketuuid",
      name: "HotelCheckout",
      component: HotelCheckout,
      meta: {
         title: "HotelCheckout",
         roles: [5],
         agencyRequired: true,
      },
   },
   {
      path: "/flight",
      name: "FlightSale",
      component: Flight,
      meta: {
         title: "FlightSale",
         roles: [1],
         agencyRequired: true,
      },
   },
   {
      path: "/rentacar",
      name: "RentACarSale",
      component: RentACar,
      meta: {
         title: "RentACar",
         roles: [22],
         agencyRequired: true,
      },
   },
   {
      path: "/packages",
      name: "PackageSearch",
      component: PackageSearch,
      meta: {
         title: "PackageSearch",
         roles: [25],
         agencyRequired: true,
      },
   },
   {
      path: "/rentacar/checkout/:searchUuid",
      name: "RentACarCheckout",
      component: RentACarCheckout,
      meta: {
         title: "RentACarCheckut",
         roles: [22],
         agencyRequired: true,
      },
   },

   {
      path: "/transfer",
      name: "TransferSale",
      component: Transfer,
      meta: {
         title: "TransferSale",
         roles: [3],
         agencyRequired: true,
      },
   },
   {
      path: "/transfer/checkout/:uuid",
      name: "TransferCheckout",
      component: TransferCheckout,
      meta: {
         title: "TransferCheckout",
         roles: [6],
         agencyRequired: true,
      },
   },
   {
      path: "/flight/checkout/:uuid",
      name: "FlightCheckout",
      component: FlightCheckout,
      meta: {
         title: "FlightCheckout",
         roles: [4],
         agencyRequired: true,
      },
   },
   {
      path: "/sale/order",
      name: "OrderSearch",
      component: OrderSearch,
      meta: {
         title: "OrderSearch",
         roles: [7],
      },
   },
   {
      path: "/order/detail/:orderUuid",
      name: "OrderDetail",
      component: OrderDetail,
      meta: {
         title: "OrderDetail",
         roles: [8],
      },
   },
   {
      path: "/report/flightSalesReport",
      name: "FlightSalesReport",
      component: FlightSalesReport,
      meta: {
         title: "FlightSalesReport",
         roles: [10],
      },
   },
   {
      path: "/report/hotelSalesReport",
      name: "HotelSalesReport",
      component: HotelSalesReport,
      meta: {
         title: "HotelSalesReport",
         roles: [11],
      },
   },
   {
      path: "/report/transferSalesReport",
      name: "TransferSalesReport",
      component: TransferSalesReport,
      meta: {
         title: "TransferSalesReport",
         roles: [21],
      },
   },
   {
      path: "/report/rentACarSalesReport",
      name: "RentACarSalesReport",
      component: RentACarSalesReport,
      meta: {
         title: "RentACarSalesReport",
         roles: [23],
      },
   },
   {
      path: "/task/taskList",
      name: "TaskList",
      component: TaskList,
      meta: {
         title: "TaskList",
         roles: [9],
      },
   },
   {
      path: "/finance/invoiceList",
      name: "InvoiceList",
      component: InvoiceList,
      meta: {
         title: "InvoiceList",
         roles: [12],
         agencyRequired: true,
      },
   },
   {
      path: "/finance/accTransactionList",
      name: "AccTransactionList",
      component: AccTransactionList,
      meta: {
         title: "AccTransactionList",
         roles: [13],
         agencyRequired: true,
      },
   },
   {
      path: "/finance/balanceUpdate",
      name: "BalanceUpdate",
      component: BalanceUpdate,
      meta: {
         title: "BalanceUpdate",
         roles: [14],
         agencyRequired: true,
      },
   },
   {
      path: "/service",
      name: "Service",
      component: Service,
      meta: {
         title: "Service",
      },
   },
   {
      path: "/announcement",
      name: "Announcement",
      component: News,
      meta: {
         title: "Announcement",
      },
   },
   {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
         title: "Login",
      },
   },
   {
      path: "/ResetPassword",
      name: "ResetPassword",
      component: ResetPassword,
      props: (route) => ({ query: route.query.token }),
   },

   {
      path: "/terms-and-conditions",
      name: "TermsAndConditions",
      component: TermsAndConditions,
      meta: {
         title: "TermsAndConditions",
      },
   },
   {
      path: "/privacy-policy",
      name: "PrivacyPolicy",
      component: PrivacyPolicy,
      meta: {
         title: "PrivacyPolicy",
      },
   },
   {
      path: "/register",
      name: "RegisterPage",
      component: Register,
      meta: {
         title: "Register",
      },
   },
   {
      path: "/settings/",
      name: "SettingsPage",
      component: Settings,
      meta: {
         title: "Settings",
         roles: [15, 16, 17, 18],
      },
   },
   {
      path: "/version",
      name: "Version",
      component: Version,
      meta: {
         title: "Version",
         roles: [20],
      },
   },

   {
      path: "*",
      name: "Dashboard",
      component: Dashboard,
      meta: {
         title: "Dashboard",
      },
   },
   {
      path: "/report/schmetterlingSalesReport",
      name: "SchmetterlingSalesReport",
      component: SchmetterlingSalesReport,
      meta: {
         title: "SchmetterlingSalesReport",
         roles: [26],
      },
   },
];

export default routerList;
