<template>
   <div id="transfer-search-form-container">
      <transition name="fade" mode="out-in">
         <SearchInformationPopup ref="transferInformationPopup" :label="'transferSearchInformation'" variant="transfer" />
      </transition>
      <!-- Delete Below -->
      <div class="transfer-search-form-wrapper">
         <div class="row">
            <div class="transfer-search-input-wrapper col-12">
               <div class="row gx-4 gy-3">
                  <div class="transfer-search-autocomplete col-12 col-lg-6">
                     <SearchAutocomplete
                        @update-route="handlerUpdateRoute"
                        @update-from="handlerUpdateFrom"
                        @update-to="handlerUpdateTo"
                        :isFormValid="isFormValid" />
                  </div>
                  <div class="col-12 col-md-8 col-lg-2">
                     <TransferDatePicker :label="$t('transfer_lblDepartureDate')" ref="transferDatePicker" @date-update="handlerDateUpdate" />
                  </div>
                  <div class="transfer-search-travelers col-12 col-md-4 col-lg-2">
                     <TransferTravellers
                        :inputLabel="$t('transfer_lblTravellers')"
                        @update-travellers="handlerTravellerUpdate"
                        ref="transferTravellers" />
                  </div>
                  <div class="action-button-wrapper col-12 col-lg-2">
                     <UIButton className="transfer" :text="$t('common_search')" @click.native="handlerSearch" :isSpinnerActive="spinnerStatus" />
                  </div>
               </div>
            </div>
         </div>
         <UIModal v-show="showModal" :noBorder="true" :hasSlot="true" @close-modal="showModal = false">
            <div class="transfer-manuel-request-form">
               <div class="transfer-manuel-request-info-wrapper col-12">
                  <div class="transfer-manuel-request-form__title">
                     <i class="gts-icon icon-popup-cursor"></i>
                     <h3 class="txt-black">{{ $t("transfer_manuelRequestModalTitle") }}</h3>
                  </div>
                  <div class="transfer-manuel-request-form__content">
                     <p>{{ $t("transfer_lblManuelRequest") }}</p>
                     <div class="transfer-manuel-request-form__info-wrapper">
                        <div class="transfer-manuel-request-form__infos --side-bg"></div>
                        <div class="transfer-manuel-request-form__transfer-info">
                           <span class="label-title">{{ $t("transfer_lblFromName") }}</span>
                           <span class="text"> {{ searchQuery.from.destinationName }}</span>
                        </div>
                        <div class="transfer-manuel-request-form__transfer-info">
                           <span class="label-title">{{ $t("transfer_lblToName") }}</span>
                           <span class="text"> {{ searchQuery.to.destinationName }}</span>
                        </div>
                        <div class="transfer-manuel-request-form__transfer-info">
                           <span class="label-title">{{ $t("transfer_lblPickupReturnDate") }}</span>
                           <div class="d-flex">
                              <span class="text"> {{ searchQuery.pickupDateTime | FormatterDate }} </span>
                              <span class="text" v-if="searchQuery.returnDate"> / {{ searchQuery.returnDate | FormatterDate }}</span>
                           </div>
                        </div>
                        <div class="transfer-manuel-request-form__transfer-info">
                           <span class="label-title">{{ $t("transfer_lblTravelerCount") }}</span>
                           <div class="d-flex">
                              <span class="text">{{ searchQuery.travellers.adult }} {{ $t("transfer_lblAdult") }}</span>
                              <span class="text" v-if="searchQuery.travellers.child > 0"
                                 >,{{ searchQuery.travellers.child }} {{ $t("transfer_lblChild") }}</span
                              >
                              <span class="text" v-if="searchQuery.travellers.infant > 0"
                                 >,{{ searchQuery.travellers.infant }} {{ $t("transfer_lblInfant") }}</span
                              >
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="transfer-manuel-request-form__cta">
                     <UIButton
                        rounded
                        className="accent-primary_outline"
                        :text="$t('transfer_manuelRequestCancel')"
                        @click.native="showModal = false" />
                     <UIButton rounded className="accent-primary" :text="$t('transfer_manuelRequestConfirm')" @click.native="handlerManuelRequest" />
                  </div>
               </div>
            </div>
         </UIModal>
      </div>
   </div>
</template>

<script>
import moment from "moment";
import { transferService } from "@/service/index.js";
import TransferTravellers from "@/components/structural/transfer/search/TransferTravellers.vue";
// import TransferDatePicker2 from "@/components/structural/transfer/search/--TransferDatePicker.vue";
import TransferDatePicker from "@/components/structural/transfer/search/TransferDatePicker.vue";
import SearchAutocomplete from "@/components/structural/transfer/search/TransferSearchAutocomplete.vue";
import SearchInformationPopup from "@/components/unitary/SearchInformationPopup.vue";
import UIModal from "@/components/unitary/form2/UIModal.vue";
import UIButton from "@/components/unitary/Button.vue";
import i18n from "@/fmt/i18n.js";
import Popup from "@/gts-popup.js";
import { generateUuid } from "@/utils";

export default {
   name: "SearchTransfer",
   components: {
      SearchAutocomplete,
      UIButton,
      UIModal,
      TransferTravellers,
      TransferDatePicker,
      SearchInformationPopup,
   },
   data() {
      return {
         searchQuery: {
            uuid: "",
            pickupDateTime: moment().add(1, "days").format(this.$constants.dateFormat),
            returnDate: null,
            travellers: { adult: 1 },
            from: {
               locationId: "",
               destinationId: "",
               destinationType: 0,
               locationType: 0,
               destinationName: "",
            },
            to: {
               locationId: "",
               destinationId: "",
               destinationType: 0,
               locationType: 0,
               destinationName: "",
            },
         },
         spinnerStatus: false,
         manuelSpinnerStatus: false,
         currentLang: "",
         isFormValid: true,
         showModal: false,
      };
   },
   created() {
      var self = this;

      self.currentLang = i18n.locale;
   },
   methods: {
      handlerDateUpdate(obj) {
         this.searchQuery.pickupDateTime = obj.from;
         this.searchQuery.returnDate = obj.to;
         setTimeout(() => {
            if (this.searchQuery.returnDate == null) {
               document.querySelector(".travellers-infos").click();
            }
         }, 100);
      },
      handlerUpdateRoute(routeId) {
         var self = this;
         self.searchQuery.routeId = routeId;
         self.isFormValid = true;
      },
      handlerUpdateFrom(from) {
         var self = this;
         self.searchQuery.from = from;
      },
      handlerUpdateTo(to) {
         var self = this;
         self.searchQuery.to = to;
         setTimeout(() => {
            if (!document.querySelector(".to-location")) {
               this.$refs.transferDatePicker.$el.querySelector(".date-wrapper").click();
            }
         }, 200);
      },
      handlerSearch() {
         const self = this;
         self.isFormValid = self.searchQuery && self.searchQuery.routeId > 0;
         // self.showModal =
         //    // this.searchQuery.routeId == 0 &&
         //    this.searchQuery.from.destinationId > 0 &&
         //    this.searchQuery.from.locationId > 0 &&
         //    this.searchQuery.from.destinationType > 0 &&
         //    this.searchQuery.from.locationType > 0 &&
         //    this.searchQuery.to.destinationId > 0 &&
         //    this.searchQuery.to.locationId > 0 &&
         //    this.searchQuery.to.destinationType > 0 &&
         //    this.searchQuery.to.locationType > 0;

         self.$parent.$emit("search-response", { isRoundTrip: false });

         if (!self.showModal) {
            if (
               self.searchQuery == {} ||
               self.searchQuery.from.destinationId == 0 ||
               self.searchQuery.from.locationType == 0 ||
               self.searchQuery.from.locationId == 0 ||
               self.searchQuery.from.destinationType == 0 ||
               self.searchQuery.to.destinationId == 0 ||
               self.searchQuery.to.locationType == 0 ||
               self.searchQuery.to.locationId == 0 ||
               self.searchQuery.to.destinationType == 0
            ) {
               self.spinnerStatus = false;
               Popup.warning(this.$i18n.t("Popup_lblEmptySearch"), this.$i18n.t("Popup_txtEmptySearch"));
               return;
            } else {
               self.spinnerStatus = true;
               self.$store.commit("transfer/setIsSearching", true);
               self.$store.commit("transfer/setSearchQuery", self.searchQuery);
               self.$store.commit("transfer/generateSearchUuid", generateUuid());
               self.searchQuery.uuid = self.$store.state.transfer.searchUuid;
               var isRoundTrip = self.searchQuery.returnDate != null;
               transferService.transferSearch(self.searchQuery).then((response) => {
                  self.$parent.$emit("search-response", { ...response, isRoundTrip: isRoundTrip });
                  //IMPORTANT:  if transfer price is passive from admin panel then show manuel request popup
                  if (Object.keys(response.transfers).length == 0) self.showModal = true;
                  // ---------------------------------------------------------------------
                  self.spinnerStatus = false;
                  self.$store.commit("transfer/setIsSearching", false);
                  self.$store.commit("transfer/setSearchQuery", null);
               });
               self.$store.commit("transfer/updateBasket", {
                  departureOfferId: 0,
                  returnOfferId: 0,
               });
               self.$store.commit("transfer/updateBasketResponse", {});
            }
         }
      },
      handlerManuelRequest() {
         const self = this;
         transferService.transferManualAdd(self.searchQuery).then((response) => {
            if (response.result.success) {
               Popup.info(this.$i18n.t("Popup_lblRequestReceived"), this.$i18n.t("Popup_lblPlsWaitForContact"));
            }
            self.showModal = false;
            self.manuelSpinnerStatus = false;
         });
      },
      handlerTravellerUpdate(travellers) {
         const self = this;
         self.searchQuery.travellers = travellers;
      },
   },
   watch: {
      "$i18n.locale"(newValue) {
         const self = this;
         self.currentLang = newValue;
      },
   },
};
</script>
<style lang="scss" scoped>
#transfer-search-form-container {
   //     div[class*="div-"] {
   //   border-color: red;
   // }
   // div[class*="col-"] {
   //    // border: 1px solid #ccc;
   // }
   .transfer-search-form-wrapper {
      .transfer-search-autocomplete {
         // width: 54%;
      }
      .transfer-search-travelers {
         // width: 18%;
      }
      .action-button-wrapper {
         display: flex;
         align-items: flex-end;

         @media (max-width: 576px) {
            margin-top: 1rem;
         }

         ::v-deep {
            button {
               width: 100%;
            }
         }
      }
      .transfer-manuel-request-form {
         padding: 1rem;
         width: 100%;
         &__title {
            text-align: center;
            color: #1e2023;
            font-size: var(--xlarge);
            letter-spacing: 0px;
            i {
               margin: auto;
               width: 114px !important;
               height: 79.06px !important;
            }
         }
         &__content {
            text-align: center;
            font-size: var(--small);
            color: #6d6e6f;
            letter-spacing: 0.22px;
            margin-top: 0.5rem;
         }
         &__info-wrapper {
            background: #f7f7f7 0% 0% no-repeat padding-box;
            border: 1px solid #ebebeb;
            border-radius: 16px;
            padding: 20px;
            position: relative;
            margin-top: 0.5rem;
            &::before {
               content: "";
               position: absolute;
               top: 0;
               left: 0;
               width: 16px; /* adjust as needed */
               height: 100%;
               background: #ebebeb 0% 0% no-repeat padding-box;
               border-radius: 8px 0px 0px 8px;
            }
            &.--side-bg {
               width: 16px;
               height: 100%;
               padding: 0;
            }
         }
         &__cta {
            margin-top: 1rem;
            display: flex;
            justify-content: space-evenly;
            .gts-btn {
               height: 40px;
            }
         }
         &__transfer-info {
            display: flex;
            flex-direction: column;
            padding-left: 24px;
            margin-top: 20px;
            align-items: flex-start;
            .label-title {
               font-weight: bold;
               font-size: var(--normal);
               color: #363636;
            }
            .text {
               color: #363636;
               font-size: var(--small);
               letter-spacing: 0;
            }
         }
      }
   }
}
</style>
<style lang="scss" scoped>
#transfer-search-form-wrapper {
   ::v-deep .travellers-inner-wrapper,
   ::v-deep .autocomplete-inner-wrapper,
   ::v-deep .datepicker-inner-wrapper {
      border: 1px solid #ccc;
      background: #f8f8f8;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 0.75rem;
      box-sizing: border-box;
      border-radius: 0.5rem;
      position: relative;
      min-height: 48px;
      cursor: default !important;

      &:focus-within {
         background-color: #f9fdff;
         border: 1px solid var(--accent-primary);
         outline: 0.25rem solid var(--accent-primary-lightest);
      }
   }
   ::v-deep .g-input-container label {
      font-weight: 500;
   }
   .search-component-wrapper {
      color: var(--txt-black);

      .search-component-inner {
         display: flex;
         flex-direction: row;
         align-items: flex-end;
         justify-content: space-between;
         margin-bottom: 1rem;
         .search-component-inner-section {
            .search-input-item {
               display: flex;
               flex-direction: column;

               label {
                  margin-bottom: 8px;
                  font-weight: 500;
               }
               input,
               select,
               .multiselect {
                  width: auto;
                  height: 50px;
                  max-height: 50px;
                  height: 50px;

                  ::v-deep .multiselect__tags {
                     display: flex;
                     justify-content: flex-start;
                     align-items: center;
                     min-height: 50px;
                     padding: 0 16px;
                     border: 1px solid #cacbcc;
                     border-radius: 12px;
                     color: var(--txt-black);

                     & .multiselect__single {
                        margin-bottom: 0;
                     }
                  }
               }
               .multiselect {
                  width: 150px;
                  max-width: 150px;
               }
            }
         }
      }
   }
}
</style>
